/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck

import type { Square } from "chess.js";
import { isNaN, toNumber, parseInt } from "lodash";
import ChessPiece from "./Piece";
import {
	BLACK_SQUARE_COLOR,
	chessColumns,
	ONE,
	Piece,
	POSSIBLE_MOVE_BORDER_COLOR,
	POSSIBLE_MOVE_BORDER_LINE_WIDTH,
	SQUARES_IN_WIDTH,
	TWO,
	WHITE_SQUARE_COLOR,
	ZERO,
} from "../Helpers/consts";
import type GamePresenter from "../GamePresenter";
import type EventHandler from "../Helpers/EventHandler";
import type { PromotionParameters } from "../interfaces";
import SceneKeys from "../Helpers/SceneKeys";

interface chessBoardSquare {
	positionNumber: number;
	positionName: Square;
	rectangle: Phaser.GameObjects.Rectangle;
	piece?: ChessPiece;
}

export default class ChessBoard extends Phaser.GameObjects.Container {
	private fen: string =
		"rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
	private readonly board: Array<chessBoardSquare> = [];
	private readonly blackColor = BLACK_SQUARE_COLOR;
	// private readonly blackColorHex = "#6666FF";
	private readonly whiteColor = WHITE_SQUARE_COLOR;
	// private readonly whiteColorHex = "#E8E8FF";
	// private readonly fontSize = "15px";
	private readonly possibleMovementCircleColor = 0x00eeff;
	private readonly possibleMovementCircleSize = 0.35;
	private readonly yOffset = 100;
	private possibleMovements: Array<Phaser.GameObjects.Arc> = [];
	private readonly flipped = false;
	private readonly presenter: GamePresenter;
	private readonly eventHandler: EventHandler;
	private selectedPice: ChessPiece | undefined = undefined;

	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		startingFen: string,
		presenter: GamePresenter,
		eventHandler: EventHandler
	) {
		super(scene, x, y);

		const chessboardOffset = 20;
		const canvasWidth = scene.game.canvas.width;
		this.width = canvasWidth - chessboardOffset;
		this.height = canvasWidth - chessboardOffset;
		this.presenter = presenter;
		this.eventHandler = eventHandler;
		this.fen = startingFen;
		// Add the board image as the background
		const boardImage = scene.add.image(0, 0, "Board");
		this.add(boardImage);
		boardImage
			.setDisplaySize(this.width, this.height)
			.setDepth(-1)
			.setOrigin(0, 0);

		// Define the offset for the squares (from top and left)
		const squareOffsetX = this.width * 0.05; // 5% of the width
		const squareOffsetY = this.width * 0.025; // 2.5% of the width

		// Adjust the effective width and height for the squares
		const effectiveWidth = this.width - squareOffsetX * 2;

		const squareSize = effectiveWidth / SQUARES_IN_WIDTH;

		let mutableSquareColorCounter = ONE;
		const chessSquares: Array<Phaser.GameObjects.GameObject> = [];

		// BOARD CREATION
		for (let numberRow = ZERO; numberRow < SQUARES_IN_WIDTH; numberRow++) {
			for (
				let letterColumn = ZERO;
				letterColumn < SQUARES_IN_WIDTH;
				letterColumn++
			) {
				mutableSquareColorCounter *= -ONE;

				// Determine the color of the square
				const squareColor =
					mutableSquareColorCounter < ZERO ? this.whiteColor : this.blackColor;

				// Calculate the position of the square with the offsets
				const xCoord = letterColumn * squareSize + squareOffsetX;
				const yCoord = numberRow * squareSize + squareOffsetY;

				// Create the square rectangle
				const rect = scene.add.rectangle(
					xCoord,
					yCoord,
					squareSize,
					squareSize,
					squareColor,
					0
				);
				rect.setOrigin(ZERO, ZERO); // Align the square to the top-left corner

				scene.physics.world.enable(rect);
				rect.on("pointerdown", () => {
					this.onSquareRectClick(rect);
				});
				// Store the square in the board array
				this.board.push({
					positionName:
						`${chessColumns[letterColumn]}${SQUARES_IN_WIDTH - numberRow}` as Square,
					positionNumber: numberRow * SQUARES_IN_WIDTH + letterColumn + ONE,
					rectangle: rect,
				});

				chessSquares.push(rect);
			}
			mutableSquareColorCounter *= -ONE; // Alternate row colors
		}

		// Add all squares to the container
		this.add(chessSquares);

		// Add the container to the scene
		scene.add.existing(this);
		// // LABEL THE FILE
		// const labelsFontDistance = 7;
		// let mutableRowNumber = 8;
		// let mutableLabelColorCounter = ONE;

		// for (
		// 	let squareNumber = 0;
		// 	squareNumber < this.board.length;
		// 	squareNumber += 8, mutableRowNumber--
		// ) {
		// 	const squareTextColor =
		// 		mutableLabelColorCounter > ZERO
		// 			? this.blackColorHex
		// 			: this.whiteColorHex;
		// 	mutableLabelColorCounter *= -ONE;

		// 	const rect = this.board[squareNumber].rectangle;
		// 	const labelText = scene.add.text(
		// 		rect.x + labelsFontDistance,
		// 		rect.y + labelsFontDistance,
		// 		`${mutableRowNumber}`,
		// 		{
		// 			fontSize: this.fontSize,
		// 			fontFamily: "Helvetica",
		// 			color: squareTextColor,
		// 		}
		// 	);
		// 	this.add(labelText);
		// }

		// // LABEL THE RANK
		// let mutableColumnNumber = 7;
		// const letterLabelOffset = 8;
		// mutableLabelColorCounter = ONE;

		// for (
		// 	let squareLetter = this.board.length - ONE;
		// 	squareLetter > this.board.length - SQUARES_IN_WIDTH - ONE;
		// 	squareLetter--, mutableColumnNumber--
		// ) {
		// 	const rect = this.board[squareLetter].rectangle;
		// 	const squareTextColor =
		// 		mutableLabelColorCounter > ZERO
		// 			? this.blackColorHex
		// 			: this.whiteColorHex;
		// 	mutableLabelColorCounter *= -ONE;

		// 	const labelText = scene.add.text(
		// 		rect.x + squareSize - labelsFontDistance - letterLabelOffset,
		// 		rect.y + squareSize - labelsFontDistance * TWO - letterLabelOffset,
		// 		`${chessColumns[mutableColumnNumber]}`,
		// 		{
		// 			fontSize: this.fontSize,
		// 			fontFamily: "Helvetica",
		// 			color: squareTextColor,
		// 		}
		// 	);
		// 	this.add(labelText);
		// }

		// LOAD STARTING PIECES
		this.loadPositionFromFen(startingFen || this.fen);

		// Center the chessboard
		// const newXposition = (scene.game.canvas.width - this.width) / TWO;
		// const newYposition = (scene.game.canvas.height - this.height) / TWO;
		// this.setPosition(newXposition, newYposition + this.yOffset);
		this.setupBar();
		this.setupTimers();
	}
	setupBar() {
		// Constants for layout and styling
		const BAR_WIDTH = 120;
		const BAR_HEIGHT = 42;
		const PIECE_SCALE = 0.04;
		const PIECE_SPACING = 20; // Horizontal spacing between pieces
		const TEXT_STYLE = {
			fontSize: "11px",
			fontFamily: "Ubuntu",
			align: "center",
		};
		const BLACK_TEXT_COLOR = "#4C5564";
		const WHITE_TEXT_COLOR = "#F6C696";

		// Add the white and black bars
		const whiteBar = this.scene.add
			.image(this.width / 2, this.height + 30, "black_bar")
			.setDisplaySize(BAR_WIDTH, BAR_HEIGHT);
		const blackBar = this.scene.add
			.image(this.width / 2, -30, "white_bar")
			.setDisplaySize(BAR_WIDTH, BAR_HEIGHT);
		whiteBar.setOrigin(0.5, 0.5);
		blackBar.setOrigin(0.5, 0.5);
		this.add(whiteBar);
		this.add(blackBar);

		// Get captured pieces
		const { b: blackCaptured, w: whiteCaptured } =
			this.presenter.getCapturedPieces();

		// Helper function to render captured pieces for a given color
		const renderCapturedPieces = (
			capturedPieces: Record<string, number>,
			barY: number,
			piecePrefix: string,
			textColor: string
		) => {
			const startX =
				blackBar.x -
				(Object.keys(capturedPieces).length * PIECE_SPACING) / 2 +
				10;

			Object.keys(capturedPieces).forEach((symbol, index) => {
				const capturedCount = capturedPieces[symbol] ?? 0;
				// Skip pieces with zero captures

				// Construct the image name for the piece
				let mutableImageName = `${piecePrefix}_`;
				const pieceType = ChessPiece.pieceTypeFromSymbol(symbol);
				const foundPiece = Piece[ChessPiece.pieceType(pieceType)];
				mutableImageName += `${foundPiece?.toLowerCase() ?? ""}`;

				// Calculate position for the piece and text
				const xPos = startX + PIECE_SPACING * index;
				const yPos = barY - 10;

				// Add the piece image
				const img = this.scene.add
					.image(xPos, yPos, mutableImageName)
					.setScale(PIECE_SCALE);
				this.add(img);
				if (capturedCount === 0) return;
				// Add the captured count text below the piece
				const text = this.scene.add.text(xPos, yPos + 10, `${capturedCount}`, {
					...TEXT_STYLE,
					color: textColor,
				});
				text.setOrigin(0.5, 0);
				this.add(text);
			});
		};

		// Render captured pieces for black and white
		renderCapturedPieces(blackCaptured, whiteBar.y, "black", BLACK_TEXT_COLOR);
		renderCapturedPieces(whiteCaptured, blackBar.y, "white", WHITE_TEXT_COLOR);
	}
	static getSquareNumberInBoard(square: string) {
		const positionNumber =
			(SQUARES_IN_WIDTH - parseInt(square[ONE])) * SQUARES_IN_WIDTH +
			(chessColumns.indexOf(square[ZERO]) + ONE);
		return positionNumber - ONE;
	}
	setupTimers(): void {
		// Constants for styling
		const CONTAINER_COLOR = 0x083c91; // Background color (#083C91)
		const BORDER_RADIUS = 8; // Rounded corners
		const PADDING = 8; // Padding inside the container
		const TEXT_STYLE = {
			fontSize: "14px",
			fontFamily: "Ubuntu",
			color: "#F1F1F3", // White text
			align: "center",
		};
		const rectWidth = 80; // Width of the rectangle
		const rectHeight = 40; // Height of the rectangle

		// Top-left timer
		this.createTimer(
			this.x + 30, // X position for top-left
			-55, // Y position for top-left
			CONTAINER_COLOR,
			BORDER_RADIUS,
			PADDING,
			rectWidth,
			rectHeight,
			TEXT_STYLE,
			"clock",
			"05:00",
			"black_timer"
			// Initial time for top-left timer
		);

		// Top-right timer
		this.createTimer(
			this.scene.scale.width - rectWidth - 60, // X position for top-right
			-55, // Y position for top-right
			CONTAINER_COLOR,
			BORDER_RADIUS,
			PADDING,
			rectWidth,
			rectHeight,
			TEXT_STYLE,
			"clock",
			"05:00",
			"white_timer"
			// Initial time for top-right timer
		);
	}

	// Helper function to create a single timer
	createTimer(
		x: number,
		y: number,
		containerColor: number,
		borderRadius: number,
		padding: number,
		rectWidth: number,
		rectHeight: number,
		textStyle: Phaser.Types.GameObjects.Text.TextStyle,
		iconKey: string,
		initialTime: string,
		registryLabel: string
	): void {
		// Create the background rounded rectangle (container)
		const container = this.scene.add.graphics();
		container.fillStyle(containerColor, 1); // Set fill color and alpha
		container.fillRoundedRect(x, y, rectWidth, rectHeight, borderRadius); // Draw rounded rectangle
		this.add(container);

		// Add the clock icon
		const clockIcon = this.scene.add
			.image(x + padding, y + padding, iconKey)
			.setDisplaySize(16, 16);
		clockIcon.setOrigin(0, 0);
		this.add(clockIcon);

		// Add the timer text
		const timerText = this.scene.add.text(
			x + padding + 24, // X position with padding
			y + padding, // Y position with padding
			initialTime, // Initial timer value
			textStyle
		);
		timerText.setOrigin(0, 0); // Align text to top-left corner
		this.add(timerText);

		// Optionally, store the timer text in the scene for later updates
		// You can use unique keys for each timer if needed
		this.scene.registry.set(registryLabel, timerText);
	}

	getBoard() {
		return this.board;
	}

	getFen() {
		return this.fen;
	}

	setFen(fen: string) {
		this.fen = fen;
	}

	getPossibleMovements() {
		return this.possibleMovements;
	}

	addPossibleMovement(squareRect: Phaser.GameObjects.Rectangle) {
		console.log(squareRect);
		const offsetPositionX = squareRect.width / TWO + this.x;
		const offsetPositionY = squareRect.width / TWO + this.y;

		const possibleMovementCircle = this.scene.add.circle(
			squareRect.x + offsetPositionX,
			squareRect.y + offsetPositionY,
			squareRect.width * 0.2,
			this.possibleMovementCircleColor,
			this.possibleMovementCircleSize
		);
		squareRect.setStrokeStyle(
			POSSIBLE_MOVE_BORDER_LINE_WIDTH,
			POSSIBLE_MOVE_BORDER_COLOR
		);

		this.possibleMovements.push(possibleMovementCircle);
	}

	setPossibleMovements(possibleMovements: Array<Phaser.GameObjects.Arc>) {
		this.possibleMovements = possibleMovements;
	}

	destroyPossibleMovements() {
		this.possibleMovements.forEach((movement) => {
			movement.destroy();
		});
		this.possibleMovements = [];
	}

	loadPositionFromFen(fen: string) {
		const fenBoard = fen.split(" ").shift();
		console.log(fenBoard);
		console.log(fen);
		if (!fenBoard) {
			return;
		}

		// Clear existing pieces from the board
		this.board.forEach((square) => {
			if (square.piece) {
				square.piece.destroy();
				square.piece = undefined;
			}
		});

		let mutableFile = 0;
		let mutableRank = 0;

		// Determine if the board is flipped
		// Assume a method `isFlipped()` exists

		for (let index = 0; index < fenBoard.length; index++) {
			const symbol = fenBoard[index];

			if (symbol === "/") {
				mutableFile = 0;
				mutableRank++;
			} else {
				if (!isNaN(toNumber(symbol))) {
					mutableFile += toNumber(symbol);
				} else {
					const pieceColor =
						symbol === symbol.toUpperCase() ? Piece.White : Piece.Black;
					const pieceType = ChessPiece.pieceTypeFromSymbol(symbol);

					// Calculate the square index based on whether the board is flipped
					const squareIndex = mutableRank * SQUARES_IN_WIDTH + mutableFile;

					const chessBoardSquare = this.board[squareIndex];
					chessBoardSquare.piece = new ChessPiece(
						pieceColor | pieceType,
						chessBoardSquare.positionName,
						chessBoardSquare.rectangle,
						this.scene,
						this.presenter.CurrentPlayer?.color ?? "white",
						(pice) => {
							this.onPiceClick(pice);
						}
					);
					this.add(chessBoardSquare.piece);
					mutableFile++;
				}
			}
		}
	}
	onPiceClick(pice: ChessPiece) {
		this.selectedPice = pice;
		this.destroyPossibleMovements();
		const square = pice.getPositionInBoard();

		const moves = this.presenter.chessGame.moves({
			square,
			verbose: true,
		});
		const possibleMoves: Set<Square> = new Set();
		moves.map((move) => possibleMoves.add(move.to));
		const currentBoard = this.getBoard();
		currentBoard.forEach((square) => {
			if (possibleMoves.has(square.positionName)) {
				const squareRect = square.rectangle;
				squareRect.setInteractive();
				this.scene.children.bringToTop(squareRect);
				this.addPossibleMovement(squareRect);
			} else {
				square.rectangle.setStrokeStyle(ZERO);
				square.rectangle.disableInteractive();
			}
		});

		this.eventHandler.callEvent("SelectPice", square);
	}
	onSquareRectClick(rectangle: Phaser.GameObjects.Rectangle) {
		if (!this.selectedPice) {
			return;
		}
		const dragablePiece = this.selectedPice;
		const offsetPositionX = rectangle.width / TWO;
		const offsetPositionY = rectangle.width / TWO;
		dragablePiece.x = rectangle.x + offsetPositionX;
		dragablePiece.y = rectangle.y + offsetPositionY;

		const currentBoard = this.getBoard();
		const verboseMoves = this.presenter.chessGame.moves({
			square: dragablePiece.getPositionInBoard(),
			verbose: true,
		});
		const sansMoves = this.presenter.chessGame.moves({
			square: dragablePiece.getPositionInBoard(),
		});
		const possibleSquaresToMove: Set<Square> = new Set();
		verboseMoves.map((move) => possibleSquaresToMove.add(move.to));
		this.destroyPossibleMovements();

		const pieceToMove = ChessPiece.pieceTypeFromNumber(
			ChessPiece.pieceType(dragablePiece.getChessPiece())
		);

		currentBoard.forEach((square) => {
			// Remove possible movement circles
			if (possibleSquaresToMove.has(square.positionName)) {
				square.rectangle.input.enabled = false;
				square.rectangle.setStrokeStyle(ZERO);
			}

			if (
				rectangle.x === square.rectangle.x &&
				rectangle.y === square.rectangle.y
			) {
				let mutableFinalMove: string = "";
				if (pieceToMove === "") {
					const mutableMoves: Array<string> = sansMoves.filter((move) =>
						move.includes(square.positionName)
					);
					if (mutableMoves.length === ONE) {
						mutableFinalMove = mutableMoves.pop()!;
					} else {
						// Is it a promotion?
						const promotionParameters: PromotionParameters = {
							rectangle: square.rectangle,
							pieceColor: dragablePiece.getColour(),
							origin: dragablePiece.getPositionInBoard(),
							target: square.positionName,
							offset: {
								x: (this.scene.game.canvas.width - this.width) / TWO,
								y: (this.scene.game.canvas.height - this.height) / TWO,
							},
						};
						this.scene.game.scene.pause(SceneKeys.MainBoard);
						this.scene.game.scene.start(
							SceneKeys.Promotion,
							promotionParameters
						);

						return;
					}

					if (square.piece) {
						square.piece.destroy();
					} else if (!square.piece && mutableFinalMove.includes("x")) {
						// Is it an en-passant?
						this.processEnPassant(dragablePiece, square.positionNumber);
					}
				} else {
					if (square.piece) {
						square.piece.destroy();
					}

					const mutableMoves: Array<string> = sansMoves.filter((move) =>
						move.includes(square.positionName)
					);
					if (mutableMoves.length === ONE) {
						mutableFinalMove = mutableMoves.pop()!;
					} else if (mutableMoves.length < ONE) {
						// Calculate possible castling O-O
						if (
							pieceToMove === "K" &&
							(square.positionName == "g1" || square.positionName == "g8")
						) {
							// Set the movement to king side castling
							mutableFinalMove = "O-O";
							// Rook is +ONE position from the square we want to move the King
							const rook = currentBoard[square.positionNumber].piece!;
							// Change Rook's position
							this.removePieceFromCurrentPosition(rook);
							// Get the squares beside the king
							const squareLeftFromKing =
								currentBoard[square.positionNumber - TWO];
							// Move rook to king's left
							squareLeftFromKing.piece = rook;
							rook.setPositionInBoard(squareLeftFromKing.positionName);
							// Move Sprite to kings left
							rook.x = squareLeftFromKing.rectangle.x + offsetPositionX;
							rook.y = squareLeftFromKing.rectangle.y + offsetPositionY;

							// Calculate possible castling O-O-O
						} else if (
							pieceToMove === "K" &&
							(square.positionName == "c1" || square.positionName == "c8")
						) {
							// Set the movement to queen side castling
							mutableFinalMove = "O-O-O";
							// Rook is -ONE position from the square we want to move the King
							const rook = currentBoard[square.positionNumber - THREE].piece!;
							// Change Rook's position
							this.removePieceFromCurrentPosition(rook);
							// Get the squares beside the king
							const squareRightFromKing = currentBoard[square.positionNumber];
							// Move rook to king's right
							squareRightFromKing.piece = rook;
							rook.setPositionInBoard(squareRightFromKing.positionName);
							// Move Sprite to kings right
							rook.x = squareRightFromKing.rectangle.x + offsetPositionX;
							rook.y = squareRightFromKing.rectangle.y + offsetPositionY;
						}
					}
				}

				// Remove piece from current position
				this.removePieceFromCurrentPosition(dragablePiece);

				// Set the new position of the piece
				square.piece = dragablePiece;
				square.piece.setPositionInBoard(square.positionName);

				this.presenter.movePice(mutableFinalMove);

				this.setFen(this.presenter.chessGame.fen());

				// console.log(`The chess lib FEN: ${ this.presenter.chessGame.fen()}`);
			}
		});
	}
	removePieceFromCurrentPosition(piece: ChessPiece) {
		const currentPositionName = piece.getPositionInBoard() as string;

		const currentPositionNumber =
			(SQUARES_IN_WIDTH - parseInt(currentPositionName[ONE])) *
				SQUARES_IN_WIDTH +
			(chessColumns.indexOf(currentPositionName[ZERO]) + ONE);
		this.board[currentPositionNumber - ONE].piece = undefined;
	}

	processEnPassant(chessPiece: ChessPiece, targetSquarePositionNumber: number) {
		const isWhitePiece = ChessPiece.isColour(
			chessPiece.getChessPiece(),
			Piece.White
		);
		const enPassantSquareNumber = isWhitePiece
			? targetSquarePositionNumber + SQUARES_IN_WIDTH - ONE
			: targetSquarePositionNumber - SQUARES_IN_WIDTH - ONE;
		const oppositePiece = isWhitePiece
			? Piece.Black | Piece.Pawn
			: Piece.White | Piece.Pawn;
		const enPassantSquare = this.board[enPassantSquareNumber];
		const enPassantPiece = enPassantSquare.piece;
		if (enPassantPiece?.getChessPiece() === oppositePiece) {
			enPassantSquare.piece?.destroy();
			this.removePieceFromCurrentPosition(enPassantPiece);
		}
	}
	flipBoard() {
		// Define offsets for the squares
		const squareOffsetX = this.width * 0.05; // 5% of the width
		const squareOffsetY = this.width * 0.025; // 2.5% of the width
		const effectiveWidth = this.width - squareOffsetX * 2;

		const squareSize = effectiveWidth / SQUARES_IN_WIDTH;

		// Reverse the board array
		this.board.reverse();

		// Update the positions of the squares and pieces
		this.board.forEach((square, index) => {
			const row = Math.floor(index / SQUARES_IN_WIDTH);
			const col = index % SQUARES_IN_WIDTH;

			// Calculate new coordinates for the square with offsets
			const newX = col * squareSize + squareOffsetX;
			const newY = row * squareSize + squareOffsetY;

			// Update the rectangle position
			square.rectangle.setPosition(newX, newY);

			// Update the position of the piece if it exists
			if (square.piece) {
				square.piece.x = newX + squareSize / 2;
				square.piece.y = newY + squareSize / 2;
				square.piece.setPositionInBoard(square.positionName);
			}
		});

		// Optionally, re-render labels if they exist
		// this.renderLabels();
	}
}

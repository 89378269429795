import { GamePresenter} from "../Types";

export default class SetInitialPlayerTurnCommand {
	private presenter: GamePresenter;
	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(config: any): void {
		const fullResponse = config.fullResponse;
		this.presenter.spawnMainBoardWins(fullResponse);
		this.presenter.setPlayerInitialTurn(config.move, fullResponse);
		console.log("initial turn is", config.move.turn);
		console.log("initial table", fullResponse.data.table);
	}
}

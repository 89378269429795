import { GamePresenter, WinConfig } from "../Types";

export default class SubBoardWinCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(config: WinConfig): void {
		console.log("SubBoard Win Command!");
		this.presenter.subBoardWin(config);
	}
}

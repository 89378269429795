import { createFileRoute } from "@tanstack/react-router";
import Ludo from "../../../containers/Ludo/index";
import { GameValidateSearch } from "@/helpers";

export const Route = createFileRoute("/ludo/$token/$matchID")({
	validateSearch: GameValidateSearch,
	component: () => {
		return <Ludo />;
	},
});

import type GamePresenter from "../GamePresenter";
import type { SocketTurnUpdateData } from "../interfaces";

export default class TurnUpdateCommand {
	private readonly presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(data: SocketTurnUpdateData): void {
		this.presenter.setPlayers(
			data.players,
			this.presenter.CurrentPlayer?.playerId ?? 0
		);
		this.presenter.renderBoard(data.FEN);
		this.presenter.changeTurn();
	}
}

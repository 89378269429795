/* eslint-disable camelcase */
import FaceNormalSVG from "@/components/icons/logo";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect, useMemo, type FunctionComponent } from "react";
import { io } from "socket.io-client";
const token =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo4OCwiaWF0IjoxNzI5NzE4OTAyLCJleHAiOjE3NjEyNzY1MDJ9.XL8r27_ug0ZUtSVvBeQtvj3-N_ldOGOvmog0New8WzI";
const HomePage: FunctionComponent = () => {
	const matchSocket = useMemo(
		() =>
			io("https://staging.peley.game/matches", {
				path: "/socket.io",
				transports: ["websocket"],
				//		autoConnect: false,
				auth: {
					token: `Access-Token=${token}`,
				},

				query: {
					EIO: "4",
				},
			}),
		[]
	);
	const navigate = useNavigate({
		from: "/",
	});
	const play = useCallback(() => {
		matchSocket.emit("quick-pair", {
			game_id: 5,
			players_count: 2,
			entry_cost: 100,
			is_stream_active: true,
		});
	}, [matchSocket]);
	useEffect(() => {
		matchSocket.on("game-engine-event", async (data: string) => {
			const socketData = JSON.parse(data);
			if (socketData.action === "engine.match_start") {
				matchSocket.off("game-engine-event");
				await navigate({
					to: "/chess/$token/$matchID",
					params: { token, matchID: socketData.matchId },
					search: {
						clientId: "10",
						keyName: "sss",
						gameMode: "player",
					},
				});
			}
			return () => matchSocket.off("game-engine-event");
		});
	});
	return (
		<div className="h-screen flex-col gap-4 w-full bg-pink-500  text-white flex justify-center items-center text-6xl uppercase">
			<FaceNormalSVG />
			Peley game hub
			<button
				onClick={() => {
					play();
				}}
			>
				ludo
			</button>
		</div>
	);
};

export default HomePage;

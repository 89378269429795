import { EventHandler, GamePresenter, ResponseData } from "../Types";

export default class SetProfileInfoCommand {
	private presenter: GamePresenter;
	private eventHandler: EventHandler;

	constructor(presenter: GamePresenter, eventHandler: EventHandler) {
		this.presenter = presenter;
		this.eventHandler = eventHandler;
	}

	execute(config: ResponseData): void {
		let players_profiles = config.data.players_profiles;
		if (players_profiles[0]?.user_id == this.eventHandler.getPlayerId()) {
			players_profiles = [...players_profiles].reverse();
		}
		this.presenter.setProfiles(players_profiles);
	}
}

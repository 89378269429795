import type GamePresenter from "../GamePresenter";
import type { Square } from "chess.js";

export default class SelectPiceCommand {
	private readonly presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(square: Square): void {
		this.presenter.getPossibleMovements(square);
	}
}

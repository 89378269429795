import { useParams, useSearch } from "@tanstack/react-router";
import { useCallback } from "react";
import { PlayerView, SpectatorView } from "./Views";

function Backgammon() {
	const { matchID, token } = useParams({
		from: "/backgammon/$token/$matchID",
	});
	const { clientId, gameMode } = useSearch({
		from: "/backgammon/$token/$matchID",
	});
	const GameView = useCallback(() => {
		return gameMode === "spectator" ? (
			<SpectatorView clientId={clientId} matchID={matchID} />
		) : (
			<PlayerView clientId={clientId} matchID={matchID} token={token} />
		);
	}, [clientId, matchID, token, gameMode]);
	return <GameView />;
}

export default Backgammon;

import SceneKeys from "../Helpers/SceneKeys";

export default class Preloader extends Phaser.Scene {
	constructor() {
		super({ key: SceneKeys.Preloader });
	}
	private readonly setupProgressBar = (): void => {
		this.load.on("progress", this.updateTargetProgress, this);
		this.load.on("complete", this.onLoadComplete, this);
		this.load.on("loaderror", this.onLoadError, this);
	};

	preload() {
		this.setupProgressBar();

		this.load.pack(
			"asset_pack",
			"../../assets/chess/chess_asset_pack.json",
			"assets"
		);
		this.loadFont("Ubuntu", "../../assets/chess/UbuntuRegular.ttf");
	}
	private readonly loadFont = (name: string, url: string): void => {
		const newFont = new FontFace(name, `url(${url})`);
		newFont
			.load()
			.then((loaded) => {
				document.fonts.add(loaded);
			})
			.catch((error) => {
				console.error(`Failed to load font: ${name}`, error);
			});
	};
	private readonly updateTargetProgress = (progress: number): void => {
		// Use a global event emitter or callback to pass progress
		if (window.phaserProgressCallback) {
			window.phaserProgressCallback(progress); // Pass progress to React
		}
	};

	private readonly onLoadComplete = (): void => {
		console.log("Assets fully loaded");
		this.scene.start(SceneKeys.MainBoard);
	};

	private readonly onLoadError = (file: Phaser.Loader.File): void => {
		console.error(`Failed to load asset: ${file.key}`);
	};
	create() {
		// this.scene.start(SceneKeys.MainBoard);
	}
}

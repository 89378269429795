class EventDispatcher {
	static addEventListener(
		eventName: string,
		callback: (event: CustomEvent) => void
	): void {
		window.addEventListener(eventName, callback as EventListener);
	}

	static removeEventListener(
		eventName: string,
		callback: (event: CustomEvent) => void
	): void {
		window.removeEventListener(eventName, callback as EventListener);
	}

	static dispatchEvent(event: CustomEvent): void {
		window.dispatchEvent(event);
	}
}
export default EventDispatcher;

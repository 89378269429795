import { GameConfig, GamePresenter } from "../Types";

export default class SetPlayerTurnCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(config: GameConfig): void {
		this.presenter.setPlayerTurn(config);
	}
}

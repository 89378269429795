import Phaser from "phaser";
import { MainBoard, Preloader, Promotion } from "../scenes";

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO, // Automatically chooses WebGL or Canvas rendering
	width: window.innerWidth, // Use the full width of the device screen
	height: window.innerHeight, // Use the full height of the device screen
	scale: {
		mode: Phaser.Scale.FIT, // Ensures the game fits within the screen while maintaining aspect ratio
		autoCenter: Phaser.Scale.CENTER_BOTH, // Centers the game canvas horizontally and vertically
		parent: "game-container", // The ID of the HTML element where the game will be rendered
		fullscreenTarget: "game-container", // Target element for fullscreen mode
		autoRound: true,
	},
	backgroundColor: "#0F1A38",
	scene: [Preloader, MainBoard, Promotion],
	audio: {
		disableWebAudio: false,
	},
	physics: {
		default: "arcade",
		arcade: {
			gravity: {
				y: 0,
				x: 0,
			},
		},
	},
	dom: {
		createContainer: true, // Enable DOM support if you need to overlay HTML elements
	},
};

// SCENES

const StartGame = () => {
	return new Phaser.Game(config);
};

export default StartGame;

import type GamePresenter from "../GamePresenter";

export default class SpawnBoardCommand {
	private readonly presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(fen: unknown): void {
		if (typeof fen !== "string") {
			throw new Error("Invalid FEN: expected a string");
		}
		this.presenter.renderBoard(fen);
		this.presenter.changeTurn();
	}
}

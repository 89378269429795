import type { GamePresenter, NewBoardData } from "../Types";

export default class AIMoveCommand {
	private readonly presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(newBoardData: NewBoardData): void {
		const data = this.convertMoveToActualPos(
			newBoardData.xPos,
			newBoardData.yPos,
			newBoardData.subBoard_id
		);
		const markerData = {
			row: data.row,
			col: data.col,
			turn: newBoardData.turn,
		};
		this.presenter.aiMove(newBoardData, markerData);
	}

	private convertMoveToActualPos(
		xPos: number,
		yPos: number,
		subBoard_id: number
	) {
		const subBoardRow = Math.floor(subBoard_id / 3) * 3;
		const subBoardCol = (subBoard_id % 3) * 3;

		const actualRow = subBoardRow + xPos;
		const actualCol = subBoardCol + yPos;

		return { row: actualRow, col: actualCol };
	}
}

import { forwardRef, useLayoutEffect, useRef } from "react";
import StartGame from "../Config/StartGameConfig";
import EventHandler from "../Helpers/EventHandler";
import FullScreenGameWrapper from "@/components/FullScreenGameWrapper";

export interface IRefPhaserGame {
	game: Phaser.Game | null;
	scene: Phaser.Scene | null;
}

interface IProps {
	currentActiveScene?: (scene_instance: Phaser.Scene) => void;
	matchID: string;
	token: string;
	clientId: string;
	onProgress: (progress: number) => void;
}

export const ChessGame = forwardRef<IRefPhaserGame, IProps>(function PhaserGame(
	{ matchID, token, onProgress },
	ref
) {
	const game = useRef<Phaser.Game | null>(null);
	const eventHandler = EventHandler.getInstance();
	useLayoutEffect(() => {
		window.phaserProgressCallback = onProgress;
		if (game.current === null) {
			eventHandler.setMatchIdAndToken(matchID, token);
			game.current = StartGame();
			if (typeof ref === "function") {
				ref({ game: game.current, scene: null });
			} else if (ref) {
				ref.current = { game: game.current, scene: null };
			}
		}

		return () => {
			if (game.current) {
				game.current.destroy(true);
				window.phaserProgressCallback = null;
				if (game.current !== null) {
					game.current = null;
				}
			}
		};
	}, [ref]);

	return (
		<FullScreenGameWrapper>
			<div id="game-container"></div>
		</FullScreenGameWrapper>
	);
});

import Phaser from "phaser";
import { Asset, assets } from "../../Constants/Configuration/AssetsConfig";

class PreLoader extends Phaser.Scene {
	private progressWidth: number;
	private progressHeight: number;
	private progressBar: Phaser.GameObjects.Graphics | null;
	private loadingText: Phaser.GameObjects.Text | null;
	private targetProgress: number; 
	private currentProgress: number;
	private progressSpeed: number; 
	private fontLoaded: boolean; 

	constructor() {
		super("PreLoader");
		this.progressWidth = 0;
		this.progressHeight = 0;
		this.progressBar = null;
		this.loadingText = null;
		this.targetProgress = 0;
		this.currentProgress = 0;
		this.progressSpeed = 0.01; 
		this.fontLoaded = false;
	}

	preload(): void {
		this.setupProgressBar();
		this.loadAssets();
	}

	/**
	 * ProgressBar Functions
	 */
	private setupProgressBar = (): void => {
		const { centerX, centerY } = this.cameras.main;
		this.progressWidth = window.innerWidth / 2;
		this.progressHeight = window.innerHeight / 50;

		this.add
			.graphics()
			.lineStyle(3, 0xffffff)
			.strokeRoundedRect(
				centerX - this.progressWidth / 2,
				centerY - this.progressHeight / 2,
				this.progressWidth,
				this.progressHeight,
				10 
			);

		this.progressBar = this.add.graphics();

		this.loadingText = this.add
			.text(centerX, centerY + this.progressHeight / 2 + 50, "Loading...", {
				fontSize: `${this.progressHeight}px`,
				color: "#ffffff",
				fontFamily: "ChildHood, Arial, sans-serif", 
			})
			.setOrigin(0.5);

		this.time.addEvent({
			delay: 100,
			callback: () => {
				if (this.fontLoaded) {
					this.loadingText!.setFontFamily("ChildHood");
				}
			},
			callbackScope: this,
			loop: true,
		});

		this.load.on("progress", this.updateTargetProgress, this);
		this.load.on("complete", this.onLoadComplete, this);
		this.load.on("loaderror", this.onLoadError, this);

		this.time.addEvent({
			delay: 16, 
			callback: this.updateProgressBar,
			callbackScope: this,
			loop: true,
		});
	};

	private updateTargetProgress = (progress: number): void => {
		this.targetProgress = progress; 
	};

	private updateProgressBar = (): void => {
		const { centerX, centerY } = this.cameras.main;

		if (this.currentProgress < this.targetProgress) {
			this.currentProgress = Math.min(
				this.currentProgress + this.progressSpeed,
				this.targetProgress
			);
		}

		this.progressBar!.clear();

		this.progressBar!.fillStyle(0xffffff, 1);
		this.progressBar!.fillRoundedRect(
			centerX - this.progressWidth / 2,
			centerY - this.progressHeight / 2,
			this.progressWidth * this.currentProgress,
			this.progressHeight,
			10 
		);

		if (this.currentProgress >= 1) {
			this.loadingText!.setText("Load Complete");
		}
	};

	private onLoadComplete = (): void => {
		console.log("Assets fully loaded");

		this.time.delayedCall(3000, () => {
			if (this.currentProgress >= 1) {
				this.loadingText!.setText("Load Complete");
			}
			this.scene.start("GameScene");
		});
	};

	private onLoadError = (file: Phaser.Loader.File): void => {
		console.error(`Failed to load asset: ${file.key}`);
		this.loadingText!.setText(`Failed to load: ${file.key}`);
	};

	/**
	 * Assets Loading
	 */
	private loadAssets = (): void => {
		assets.forEach((asset: Asset) => {
			if (
				asset.path.endsWith(".png") ||
				asset.path.endsWith(".jpg") ||
				asset.path.endsWith(".jpeg")
			) {
				this.load.image(asset.key, asset.path);
			} else if (asset.path.endsWith(".wav") || asset.path.endsWith(".mp3")) {
				this.load.audio(asset.key, asset.path);
			} else if (asset.path.endsWith(".ttf") || asset.path.endsWith(".otf")) {
				this.loadFont(asset.key, asset.path);
			}
		});
	};

	private loadFont = (name: string, url: string): void => {
		const newFont = new FontFace(name, `url(${url})`);
		newFont
			.load()
			.then((loaded) => {
				document.fonts.add(loaded);
				this.fontLoaded = true; 
			})
			.catch((error) => {
				console.error(`Failed to load font: ${name}`, error);
			});
	};
}

export default PreLoader;
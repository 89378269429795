import type { Square } from "chess.js";
import type { Piece } from "../Helpers/consts";
import type { Profile } from "@/types/global";

export interface PromotionParameters {
	rectangle: Phaser.GameObjects.Rectangle;
	pieceColor: Piece.Black | Piece.White;
	origin: Square;
	target: Square;
	offset?: {
		x: number;
		y: number;
	};
}

export interface PromotionOptionSelected {
	fullName: string;
	pieceName: string;
	origin: Square;
	target: Square;
	pieceColor: Piece.Black | Piece.White;
}
export interface ChessPlayer {
	playerId: number;
	isLeftTheGame: boolean;
	color: "black" | "white";
	isConnect: boolean;
	remainingTime: number;
}
export interface ChessBoardData {
	game_id: string;
	FEN: string;
	stateVersion: number;
	isDraw: boolean;
	players: Array<ChessPlayer>;
	currentPlayerId: number;
	current_user_id: number;
	players_profiles: Array<Profile>;
}
export interface SocketActionData<T = undefined> {
	id: string;
	matchId: string;
	action: ChessSocketEventVariant;
	stateVersion: number;
	serverTime: Date;
	data: T;
}
export const enum ChessSocketEventVariant {
	TUNE_UPDATED_EVENT = "chess.tune_updated",
	PIECE_MOVED_EVENT = "chess.piece_moved",
	PIECE_CAPTURED_EVENT = "chess.piece_captured",
	CHECKED_EVENT = "chess.checked",
	CHECKMATED_EVENT = "chess.checkmated",
	DRAW_REQUESTED_EVENT = "chess.draw_requested",
	DRAW_ANSWERED_EVENT = "chess.draw_answered",
	END_GAME = "engine.match_result",
}
export interface SocketResultData {
	results: PlayerResult[];
}

export interface PlayerResult {
	player_id: number;
	points: number;
	coins: number;
	position: number;
	status: string;
}
export interface SocketTurnUpdateData {
	FEN: string;
	currentPlayerId: number;
	players: Array<ChessPlayer>;
}

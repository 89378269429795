// ** see this page here ** //:

import FullScreenGameWrapper from "@/components/FullScreenGameWrapper";
import GameView from "./GameView";
import { useParams } from "@tanstack/react-router";

function TicTacToe() {
	const { matchID, token } = useParams({
		from: "/XO/$token/$matchID",
	});
	// const { clientId, gameMode } = useSearch({
	// 	from: "/ludo/$token/$matchID",
	// });

	if (!matchID || !token) {
		return <div>Invalid match ID or token</div>;
	}

	return (
		<FullScreenGameWrapper>
			<GameView
				gameMode={"reconnect"}
				lobbyId={""}
				matchID={matchID}
				token={token}
			/>
		</FullScreenGameWrapper>
	);
}

export default TicTacToe;

import { WiningConditions } from "./Configuration/WiningConditionConfig";
import { winingConditions } from "./Configuration/WiningConditionConfig";
import { board } from "./Configuration/BoardConfig";
import { Asset } from "./Configuration/AssetsConfig";
import { assets } from "./Configuration/AssetsConfig";
export default class GameConfig {
	public mapSize: number;
	public boardSize: number;
	public boardColors: number[];
	public xColor: string;
	public oColor: string;
	public separatorLineColor: number;
	public winingCondition: WiningConditions;
	public assets: Asset[];
	public cellBorderColor: number;

	constructor() {
		this.mapSize = board.mapSize;
		this.boardSize = board.boardSize;
		this.boardColors = board.boardColors;
		this.xColor = board.xColor;
		this.oColor = board.oColor;
		this.separatorLineColor = board.separatorLineColor;
		this.winingCondition = winingConditions;
		this.assets = assets;
		this.cellBorderColor = board.cellBorderColor;
	}

	setMapSize(size: number): void {
		this.mapSize = size;
	}

	setBoardSize(size: number): void {
		this.boardSize = size;
	}

	setBoardColors(colors: number[]): void {
		this.boardColors = colors;
	}

	setWinningCondition(condition: WiningConditions): void {
		this.winingCondition = condition;
	}
}

import type { ReactNode } from "react";

interface Props {
	children: ReactNode;
}

function FullScreenGameWrapper({ children }: Props) {
	return (
		<div className="flex items-center justify-center w-full h-screen overflow-hidden bg-white font-Ubuntu">
			<div className="w-full h-full">{children}</div>
		</div>
	);
}

export default FullScreenGameWrapper;

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { Square } from "chess.js";
import { Piece } from "../Helpers/consts";

export default class ChessPiece extends Phaser.GameObjects.Image {
	static typeMask = 0b00111;
	static blackMask = 0b10000;
	static whiteMask = 0b01000;
	static colourMask = this.whiteMask | this.blackMask;
	private readonly chessPiece: number;
	private positionInBoard: Square;
	private readonly isCurrentPlayerPice: boolean = false;

	constructor(
		piece: number,
		positionInBoard: Square,
		squareParent: Phaser.GameObjects.Rectangle,
		scene: Phaser.Scene,
		currentPlayerColor: "white" | "black",
		onClick: (pice: ChessPiece) => void
	) {
		const isPieceColorBlack = ChessPiece.isColour(piece, Piece.Black);

		let mutableImageName = "";
		if (isPieceColorBlack) {
			mutableImageName += "black_";
		} else {
			mutableImageName += "white_";
		}
		// console.log(foundPiece?.toLowerCase());
		const foundPiece = Piece[ChessPiece.pieceType(piece)];
		mutableImageName += `${foundPiece?.toLowerCase() ?? ""}`;

		const squareCenter = squareParent.width / 2;
		const xPos = squareParent.x;
		const yPos = squareParent.y;

		super(scene, xPos + squareCenter, yPos + squareCenter, mutableImageName);
		if (isPieceColorBlack) {
			this.isCurrentPlayerPice = currentPlayerColor === "black";
		} else {
			this.isCurrentPlayerPice = currentPlayerColor === "white";
		}
		this.chessPiece = piece;
		this.positionInBoard = positionInBoard;
		this.setScale(0.07);
		if (this.isCurrentPlayerPice) {
			this.setInteractive();
			this.on("pointerdown", () => {
				onClick(this);
			});
		}
		scene.add.existing(this);
	}

	static pieceTypeFromSymbol(symbol: string) {
		switch (symbol.toLowerCase()) {
			case "k":
				return Piece.King;
			case "p":
				return Piece.Pawn;
			case "n":
				return Piece.Knight;
			case "b":
				return Piece.Bishop;
			case "r":
				return Piece.Rook;
			case "q":
				return Piece.Queen;
			default:
				return Piece.None;
		}
	}

	static pieceTypeFromNumber(pieceType: number) {
		switch (pieceType) {
			case Piece.King:
				return "K";
			case Piece.Pawn:
				return "";
			case Piece.Knight:
				return "N";
			case Piece.Bishop:
				return "B";
			case Piece.Rook:
				return "R";
			case Piece.Queen:
				return "Q";
			default:
				return "";
		}
	}

	static isColour(piece: Piece, colour: Piece) {
		return (piece & this.colourMask) === colour;
	}

	static pieceType(piece: Piece) {
		return piece & this.typeMask;
	}

	getColour() {
		const isPieceColorBlack = ChessPiece.isColour(this.chessPiece, Piece.Black);
		return isPieceColorBlack ? Piece.Black : Piece.White;
	}

	getChessPiece() {
		return this.chessPiece;
	}

	getPositionInBoard() {
		return this.positionInBoard;
	}

	setPositionInBoard(positionInBoard: Square) {
		this.positionInBoard = positionInBoard;
	}
}

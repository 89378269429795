/* eslint-disable camelcase */

import type { Move } from "chess.js";
import type EventHandler from "../Helpers/EventHandler";
import type SocketManager from "../Helpers/SocketManager";

export default class PiceMoveCommand {
	private readonly eventHandler: EventHandler;
	private readonly socketManager: SocketManager;

	constructor(eventHandler: EventHandler, socketManager: SocketManager) {
		this.eventHandler = eventHandler;
		this.socketManager = socketManager;
	}

	execute(move: Move): void {
		console.log("Player Action Command");

		const requestData = {
			match_id: this.eventHandler.getMatchId(),
			action_name: "chess.move",
			data: move,
		};

		try {
			console.log("Player Action on", requestData.data);
			this.socketManager.emitUserGameAction(requestData);
			console.log("Player action sent successfully.");
		} catch (error) {
			console.error("Error executing PlayerActionCommand:", error);
		}
	}
}

import { GamePresenter } from "../Types";

export default class FinishGameCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}
	execute(): void {
		console.log("Game Finished!");
		this.presenter.gameComplete();
	}
}

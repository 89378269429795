/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUnityContext } from "react-unity-webgl";
import {
	type BackgammonBoardData,
	type DiceData,
	type SocketActionData,
	type SocketDiceSelectData,
	type SocketPiceMovedData,
	type SocketTurnData,
	type SocketPiceUndoData,
	type SocketResultData,
	type BackgammonPlayer,
	type DiceValues,
	BackgammonSocketEventVariant,
	CheckerPositionVariants,
	unityEvents,
	BackgammonTurnsVariants,
} from "../../types";
import {
	getSquadFromPlayerId,
	SocketMatchDataToBackgammonBoardData,
} from "../../helpers";
import { useAblyRealtime } from "@/hooks";
import { BACKGAMMON_GAME_PATH } from "../../Constants";
import {
	sendMessageToReactNative,
	sendUnityProfileToReactNative,
} from "@/helpers";

interface Props {
	matchID: string;
	clientId: string;
}

function UseBackgammonSpectator({ matchID, clientId }: Props) {
	const [initialized, setInitialized] = useState(false);
	const [boardData, setBoardData] = useState<BackgammonBoardData | undefined>(
		undefined
	);
	const [isConnected, setIsConnected] = useState(false);
	const {
		unityProvider,
		loadingProgression,
		isLoaded,
		addEventListener,
		removeEventListener,
		sendMessage,
	} = useUnityContext({
		loaderUrl: BACKGAMMON_GAME_PATH.LOADER_URL,
		dataUrl: BACKGAMMON_GAME_PATH.DATA_URL,
		frameworkUrl: BACKGAMMON_GAME_PATH.FRAMEWORK_URL,
		codeUrl: BACKGAMMON_GAME_PATH.CODE_URL,
	});
	const {
		MatchPresentsHandler,
		clientChannel,
		matchEventsChannel,
		matchRequestChannel,
		realtime,
	} = useAblyRealtime({
		clientID: clientId,
		matchID: matchID,
	});
	const loadingPercentage = useMemo(
		() => Math.round(loadingProgression * 100),
		[loadingProgression]
	);
	const getProfile = (id: number) => {
		return boardData?.players_profiles.find((item) => item.user_id === id);
	};
	const MatchRequestHandler = useCallback(async () => {
		await clientChannel.presence.enter();
		await MatchPresentsHandler();
		await clientChannel.subscribe((message) => {
			const data = message.data as BackgammonBoardData;
			const normalizedData = SocketMatchDataToBackgammonBoardData({
				...data,
				current_user_id: 0,
			});
			setBoardData(normalizedData);
		});
		await matchRequestChannel.presence.enter();
		await matchRequestChannel.publish(
			"get-match-data",
			JSON.stringify({
				matchId: matchID,
				clientId,
				gameType: "Backgammon",
			})
		);
	}, [matchRequestChannel, clientChannel]);

	const HandleSocketTurnUpdate = useCallback(
		(playerId: number, timer = 60000) => {
			try {
				if (boardData) {
					const playerColor = getSquadFromPlayerId(playerId, boardData);

					sendMessage("Game Manager", "changeTurn", playerColor);
					sendMessage(playerColor, "SetTimer", timer);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[boardData, sendMessage]
	);
	const HandleSocketRollDice = useCallback(
		(diceValues: Array<DiceValues>) => {
			try {
				if (boardData) {
					sendMessage(
						"DiceManager",
						"RollDice",
						diceValues.map((dice) => dice.value).join(",")
					);
				}
			} catch (error) {
				console.log(error);
			}
		},
		[sendMessage, boardData]
	);
	// unity listeners

	useEffect(() => {
		if (isLoaded) {
			addEventListener(
				unityEvents.PROFILE_CLICKED,
				sendUnityProfileToReactNative
			);
		}
		return () => {
			removeEventListener(
				unityEvents.PROFILE_CLICKED,
				sendUnityProfileToReactNative
			);
		};
	}, [addEventListener, removeEventListener, isLoaded]);
	// socket events
	const MatchEventsHandler = useCallback(async () => {
		await matchEventsChannel.presence.enter();
		await matchEventsChannel.subscribe((message) => {
			const socketData = message.data;
			if (boardData) {
				switch ((socketData as SocketActionData).action) {
					case BackgammonSocketEventVariant.ROLLED_DICE: {
						const diceData = socketData as SocketActionData<DiceData>;
						HandleSocketRollDice(diceData.data.diceValues);
						break;
					}
					case BackgammonSocketEventVariant.DICE_SELECT: {
						const options =
							socketData as SocketActionData<SocketDiceSelectData>;
						const playerColor = getSquadFromPlayerId(
							options.data.playerId,
							boardData
						);

						sendMessage(playerColor, "DeactivePices");
						sendMessage("DiceManager", "DeactiveAllDice");
						sendMessage("DiceManager", "ActiveDice", options.data.selectedDice);
						sendMessage(
							playerColor,
							"PiceActive",
							options.data.moveOptions
								.map((option) => option.checkerId)
								.join(",")
						);

						break;
					}
					case BackgammonSocketEventVariant.PLAYER_MOVE: {
						const options = socketData as SocketActionData<SocketPiceMovedData>;

						const playerColor = getSquadFromPlayerId(
							options.data.playerId,
							boardData
						);

						if (
							options.data.move.toPosition.type === CheckerPositionVariants.OFF
						) {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.checkerId},25`
							);
						} else {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.checkerId},${options.data.move.toPosition.index}`
							);
						}
						sendMessage("DiceManager", "UseDice", options.data.selectedDice);
						options.data.pipCount.forEach((pipCount) => {
							const playerColor = getSquadFromPlayerId(
								pipCount.playerId,
								boardData
							);
							sendMessage(
								playerColor,
								"UpdatePipCount",
								pipCount.pipCount.toString()
							);
						});
						if (options.data.playerId === boardData.current_user_id) {
							sendMessage("DiceManager", "showUndoConfirmButton");
							sendMessage("DiceManager", "ActiveRandomDice");
						}
						break;
					}
					case BackgammonSocketEventVariant.UNDO: {
						const options = socketData as SocketActionData<SocketPiceUndoData>;

						const playerColor = getSquadFromPlayerId(
							options.data.playerId,
							boardData
						);

						if (
							options.data.move.move.fromPosition.type ===
							CheckerPositionVariants.OFF
						) {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.move.move.checkerId},25`
							);
						}
						if (
							options.data.move.move.toPosition.type ===
							CheckerPositionVariants.OFF
						) {
							sendMessage(playerColor, "UndoOff");
						}
						if (
							options.data.move.move.fromPosition.type ===
							CheckerPositionVariants.BAR
						) {
							sendMessage(
								playerColor,
								"CapturePice",
								options.data.move.move.checkerId
							);
						} else {
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.move.move.checkerId},${options.data.move.move.fromPosition.index}`
							);
						}
						if (options.data.move.hitChecker) {
							const playerColor = getSquadFromPlayerId(
								options.data.move.hitChecker.playerId,
								boardData
							);
							sendMessage(
								playerColor,
								"MovePice",
								`${options.data.move.hitChecker.checkerId},${options.data.move.hitChecker.fromPosition.index}`
							);
						}
						sendMessage(
							"DiceManager",
							"UnUseDice",
							options.data.move.selectedDice
						);
						sendMessage("DiceManager", "DeactiveConfirmButton");
						sendMessage("DiceManager", "DeactiveAllDice");

						options.data.pipCount.forEach((pipCount) => {
							const playerColor = getSquadFromPlayerId(
								pipCount.playerId,
								boardData
							);
							sendMessage(
								playerColor,
								"UpdatePipCount",
								pipCount.pipCount.toString()
							);
						});

						break;
					}
					case BackgammonSocketEventVariant.TURN_UPDATE: {
						const TurnData = socketData as SocketActionData<SocketTurnData>;

						HandleSocketTurnUpdate(TurnData.data.currentTurnPlayer.playerId);

						break;
					}
					case BackgammonSocketEventVariant.CAN_CONFIRM: {
						const result = socketData as SocketActionData<BackgammonPlayer>;
						if (result.data.playerId === boardData.current_user_id)
							sendMessage("DiceManager", "ActiveConfirmButton");

						break;
					}
					case BackgammonSocketEventVariant.END_GAME: {
						const gameResult = socketData as SocketActionData<SocketResultData>;
						sendMessage("Game Manager", "WinHandler");
						sendMessageToReactNative("endGame", { ...gameResult.data });
						break;
					}
					case BackgammonSocketEventVariant.TIME_OUT: {
						const result = socketData as SocketActionData<BackgammonPlayer>;

						const playerColor = getSquadFromPlayerId(
							result.data.playerId,
							boardData
						);
						sendMessage(
							playerColor ?? "",
							"SetTimeOuts",
							result.data.faultCount
						);

						break;
					}

					default:
						break;
				}
			}
		});
	}, [matchEventsChannel, sendMessage, boardData, initialized]);
	useEffect(() => {
		if (isLoaded) {
			MatchRequestHandler();
		}
	}, [isLoaded]);
	useEffect(() => {
		if (initialized) {
			MatchEventsHandler();
		}
	}, [initialized]);

	useEffect(() => {
		function onDisconnect() {
			setIsConnected(false);
			setInitialized(false);
			setBoardData(undefined);
			console.log("socket disconnected");
		}
		function onConnect() {
			setIsConnected(true);
			console.log("connected");
		}

		realtime.connection.on("connected", onConnect);

		realtime.connection.on("disconnected", onDisconnect);

		return () => {
			realtime.connection.off();
		};
	}, [realtime]);

	// initializer
	useEffect(() => {
		if (isLoaded && isConnected && boardData && !initialized) {
			sendMessage(
				"Game Manager",
				"setCurrentPlayer",
				boardData.currentPlayerColor
			);
			boardData.players.forEach((player) => {
				sendMessage(
					player.color,
					"GameInitializer",
					JSON.stringify({
						piceList: player.checkers,
						pipCount: player.pipCount,
						isCurrentPlayer: player.color === BackgammonTurnsVariants.WHITE,
					})
				);

				const profile = getProfile(player.playerId);

				sendMessage(
					player.color,
					"ActiveProfile",
					JSON.stringify({
						...profile,
						profile_image: profile?.profile_image.replace(".svg", ".png"),
						TimeOutsCount: player.faultCount,
						username: profile?.full_name ?? profile?.username,
						isCurrentPlayer: player.color === BackgammonTurnsVariants.WHITE,
					})
				);
			});

			HandleSocketTurnUpdate(
				boardData.LastTurn.playerId,
				boardData.remainingTime
			);
			if (
				boardData.LastTurn.step === "MOVE_CHECKER" ||
				boardData.LastTurn.step === "CONFIRM_TURN"
			) {
				HandleSocketRollDice(boardData.LastTurn.diceValues);
			}
			setInitialized(true);
			sendMessageToReactNative("gameStarted", {});
		}
	}, [isLoaded, boardData, sendMessage, HandleSocketTurnUpdate]);
	return {
		loadingPercentage,
		unityProvider,
		isLoaded,
		isConnected,
		initialized,
	};
}
export default UseBackgammonSpectator;

import GamePresenter from "../GamePresenter/GamePresenter";
import GameScene from "../GameView/Scenes/GameScene";

export default class StartMatchCommand {
	private view: GameScene;
	private presenter: GamePresenter;

	constructor(view: GameScene, presenter: GamePresenter) {
		this.view = view;
		this.presenter = presenter;
	}

	execute(currentTurn: string): void {
		const timer = this.presenter.getTimer();
		this.presenter.pauseAndResetTimer(timer);
		this.view.selectAllSubGrids();
		this.view.updateTurnText(currentTurn);
	}
}

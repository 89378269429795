import { SuperTicTacToe } from "../GameServices/super-tic-tac-toe";
import type GameScene from "../GameView/Scenes/GameScene";
import type { GameConfig, MarkerData, ReconnectAPIResponse } from "../Types";

export default class GamePresenter {
	private readonly view: GameScene;
	private readonly gameConfig: { boardSize: number; mapSize?: number };
	public game: SuperTicTacToe;
	private currentSubBoardTurn: number | null;

	constructor(view: GameScene) {
		this.view = view;
		this.gameConfig = this.view?.gameConfig || { boardSize: 3 };
		this.game = new SuperTicTacToe(
			this.gameConfig.boardSize,
			this.gameConfig.boardSize
		);
		this.currentSubBoardTurn = null;
	}

	getTimer(): number {
		const timer = this.view.getTimer();
		return timer;
	}

	subBoardWin(config: GameConfig): void {
		if (config.xo && config.x !== undefined && config.y !== undefined) {
			this.view.winMap(config.xo, config.x, config.y);
		}
	}

	renderBoard(newBoard: Array<Array<Array<Array<any>>>>): void {
		if (!this.validateBoards(newBoard)) return;
		console.log(`this board rendering ${newBoard}`);

		for (let row = 0; row < this.gameConfig.boardSize; row++) {
			for (let col = 0; col < this.gameConfig.boardSize; col++) {
				for (let x = 0; x < (this.gameConfig.mapSize || 3); x++) {
					for (let y = 0; y < (this.gameConfig.mapSize || 3); y++) {
						const marker = newBoard[row]![col]![x]![y]!;
						if (marker !== "Z") {
							this.drawMarker9x9(row, col, x, y, marker);
							this.game.playPrevMoves(row, col, x, y, marker);
						}
					}
				}
			}
		}
		console.log("(game board:)");
		console.log(this.game.getBoard());
	}

	drawMarker(row: number, col: number, player: string): void {
		const bix = Math.floor(row / 3);
		const biy = Math.floor(col / 3);
		const ix = Math.floor(row % 3);
		const iy = Math.floor(col % 3);
		this.view.putXO(
			player.toLowerCase(),
			bix,
			biy,
			ix,
			iy,
			this.view.actualCellSize!
		);
	}

	drawMarker9x9(
		row: number,
		col: number,
		x: number,
		y: number,
		player: string
	): void {
		this.view.putXO9x9(
			player.toLowerCase(),
			row,
			col,
			x,
			y,
			this.view.actualCellSize!
		);
	}

	validateBoards(newBoard: Array<Array<Array<Array<any>>>>): boolean {
		if (!newBoard) {
			console.error("renderBoard: currentBoard or newBoard is undefined.");
			return false;
		}
		if (
			newBoard.length !== this.gameConfig.boardSize ||
			newBoard.some((row) => row.length !== (this.gameConfig.mapSize || 3))
		) {
			console.error(
				"renderBoard: currentBoard or newBoard has invalid dimensions."
			);
			return false;
		}
		return true;
	}

	handleTimeOut(config: any): void {
		this.view.removePlayerLife(config);
		this.view.selectAllSubGrids();
	}

	resetGame(): void {
		this.view.resetGame(1000);
	}

	pauseAndResetTimer(time: number): void {
		this.view.pauseTimer();
		this.view.restartTimer(time);
	}

	setPlayerName(playerName: string) {
		this.view.playerName = playerName;
		console.log("playerName", playerName);
	}

	setProfiles(player_profiles: any): void {
		this.view.setProfiles(player_profiles);
	}

	spawnMainBoardWins(fullResponse: ReconnectAPIResponse): void {
		for (let index = 0; index < 9; index++) {
			const subBoardStatus = fullResponse.data.table[index]?.status!;
			if (subBoardStatus == "RUNNING") continue;
			const { row, col } = this.convertIndexToMatrix(
				index,
				this.gameConfig.boardSize
			);
			this.view.winMap(subBoardStatus.toUpperCase(), row, col);
		}
	}

	setPlayerInitialTurn(
		config: GameConfig,
		fullResponse: ReconnectAPIResponse
	): void {
		if (config.turn) {
			const nextTurn = config.turn.toLocaleUpperCase() == "X" ? "O" : "X";
			this.game.currentPlayer = nextTurn;
			const subBoardID = config.subBoard_id!;
			const subBoardStatus = fullResponse.data.table[subBoardID]?.status;

			setTimeout(() => {
				if (subBoardStatus != "RUNNING") {
					this.view.selectAllSubGrids();
				}
				this.setCurrentSubBoard(config);
				this.activateNextSubGrid(config);
			}, 1000);
		}
	}

	setPlayerTurn(config: GameConfig): void {
		if (config.turn) {
			const nextTurn = config.turn.toLocaleUpperCase() == "X" ? "O" : "X";
			this.game.currentPlayer = nextTurn;
			this.view.updateTurnText(nextTurn.toString());
		}
	}

	highlightWinLine(result: { line: any }): void {
		this.view.highlightWinLine(result.line);
	}

	gameComplete(): void {
		this.view.resetGame(2000);
		const timer = this.getTimer();
		this.pauseAndResetTimer(timer);
		this.view.deSelectAllSubGrids();
		this.view.pauseTimer();
		this.view.showGameFinishedText();
	}

	canPlayerPlacePiece(subBoardConfig: GameConfig): boolean {
		const { xPos, yPos, ySubPos, xSubPos } = subBoardConfig;
		if (
			xPos === undefined ||
			yPos === undefined ||
			ySubPos === undefined ||
			xSubPos === undefined
		) {
			return false;
		}
		const subBoard = this.game.getBoard()[xSubPos]![ySubPos]!;
		const currentCell = subBoard[xPos]![yPos]!;

		return currentCell === "";
	}

	putPiece(config: GameConfig): void {
		this.view.eventHandler.callEvent("playerAction", config);
	}

	aiMove(config: GameConfig, markerData: MarkerData): void {
		this.drawMarker(markerData.row, markerData.col, markerData.turn);
		this.updateBoard(config);
		this.setCurrentSubBoard(config);
		this.view.eventHandler.callEvent("checkSubBoard", config);
		console.log("Main Board Winners", this.game.getMainBoardWinners());
		this.activateNextSubGrid(config);
		const timer = this.getTimer();
		this.pauseAndResetTimer(timer);
	}

	setCurrentSubBoard(config: GameConfig): void {
		const { xSubPos, ySubPos } = config;
		if (xSubPos !== undefined && ySubPos !== undefined) {
			this.currentSubBoardTurn = this.convertMatrixToIndex(xSubPos, ySubPos);
		}
	}

	activateNextSubGrid(config: GameConfig): void {
		const { xPos, yPos } = config;
		console.log("xPos", xPos, "yPos", yPos);
		if (
			xPos == undefined ||
			yPos == undefined ||
			xPos < 0 ||
			yPos < 0 ||
			xPos >= this.gameConfig.boardSize ||
			yPos >= this.gameConfig.boardSize
		)
			return;

		if (this.game.getMainBoardWinners()[xPos]![yPos]!) {
			this.view.selectAllSubGrids();
			return;
		}
		this.view.selectSubGrid(xPos, yPos);
	}

	getCurrentSubBoard(): number | null {
		return this.currentSubBoardTurn;
	}

	convertMatrixToIndex(x: number, y: number): number {
		return x * 3 + y;
	}

	convertIndexToMatrix(
		index: number,
		numberCols: number
	): { row: number; col: number } {
		const row = Math.floor(index / numberCols);
		const col = index % numberCols;
		return { row, col };
	}

	updateBoard(cell: GameConfig): void {
		const { turn, xPos, yPos, subBoard_id } = cell;
		if (
			turn !== undefined &&
			xPos !== undefined &&
			yPos !== undefined &&
			subBoard_id !== undefined
		) {
			const { row, col } = this.convertIndexToMatrix(
				subBoard_id,
				this.gameConfig.boardSize
			);
			console.log(`** clicked on cell ${row},${col},${xPos},${yPos}**`);
			this.game.play(row, col, xPos, yPos, turn);
		}
	}

	errorInSelectCell(cell: GameConfig): void {
		const { xPos, yPos, subBoard_id } = cell;
		if (xPos !== undefined && yPos !== undefined && subBoard_id !== undefined) {
			const { row, col } = this.convertIndexToMatrix(
				subBoard_id,
				this.gameConfig.boardSize
			);
			console.log(`** error on cell ${row},${col},${xPos},${yPos}**`);
			this.view.errorInSelectCell(row, col, xPos, yPos);
		}
	}
}

import { createFileRoute } from "@tanstack/react-router";
import { GameValidateSearch } from "@/helpers";
import TicTacToe from "@/containers/TicTacToe";

export const Route = createFileRoute("/XO/$token/$matchID")({
	validateSearch: GameValidateSearch,
	component: () => {
		return <TicTacToe />;
	},
});

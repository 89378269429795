import type GamePresenter from "../GamePresenter";
import type EventHandler from "../Helpers/EventHandler";
import type { ChessBoardData } from "../interfaces";

export default class SetPlayersCommand {
	private readonly presenter: GamePresenter;
	private readonly eventHandler: EventHandler;
	constructor(eventHandler: EventHandler, presenter: GamePresenter) {
		this.presenter = presenter;
		this.eventHandler = eventHandler;
	}

	execute(boardData: ChessBoardData): void {
		this.presenter.setPlayers(boardData.players, boardData.current_user_id);
		this.eventHandler.currentUserId = boardData.current_user_id;
	}
}

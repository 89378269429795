import { SocketManager, StartGameConfig } from "../Types";

export default class StartGameCommand {
	private socketManager: SocketManager;

	constructor(socketManager: SocketManager) {
		this.socketManager = socketManager;
	}

	execute(config: StartGameConfig): void {
		console.log("Game Started!");

		if (config.eventName == "join-lobby") {
			this.socketManager.gameOptions(config.eventName, config.lobbyId);
			return;
		}
		this.socketManager.gameOptions(config.eventName);
	}
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import type GamePresenter from "../GamePresenter/GamePresenter";
import type { EventHandler } from "../Types";

export default class SpawnBoardCommand {
	private readonly presenter: GamePresenter;
	private readonly eventHandler: EventHandler;

	constructor(presenter: GamePresenter, eventHandler: EventHandler) {
		this.presenter = presenter;
		this.eventHandler = eventHandler;
	}

	execute(jsonData: any): void {
		const newBoard4D = Array.from({ length: 3 }, () =>
			Array.from({ length: 3 }, () =>
				Array.from({ length: 3 }, () => Array.from({ length: 3 }, () => "Z"))
			)
		);
		jsonData.data.table.forEach((subBoard: any) => {
			const subBoardId = subBoard.id;
			const row = Math.floor(subBoardId / 3);
			const col = subBoardId % 3;

			for (let x = 0; x < 3; x++) {
				for (let y = 0; y < 3; y++) {
					newBoard4D[row]![col]![x]![y] = subBoard.table[x][y];
				}
			}
		});
		console.log("** spawned board table is: **");
		console.log(jsonData.data.table);
		console.log("-- table generated is: --");
		console.log(newBoard4D);

		this.presenter.renderBoard(newBoard4D);
	}
}

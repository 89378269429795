import type SocketManager from "./SocketIOManager";
import type EventHandler from "../GameServices/EventServices/EventHandler";
import type { GameConfig } from "../Types";

export default class PlayerActionCommand {
	private readonly eventHandler: EventHandler;
	private readonly socketManager: SocketManager;

	constructor(eventHandler: EventHandler, socketManager: SocketManager) {
		this.eventHandler = eventHandler;
		this.socketManager = socketManager;
	}

	execute(gameConfig: GameConfig): void {
		console.log("Player Action Command");
		const { xPos, yPos, subBoard_id, turn } = gameConfig;

		const requestData = {
			match_id: this.eventHandler.getMatchId(),
			action_name: "XO.selectPosition",
			player_id: this.eventHandler.getPlayerId(),
			data: {
				subBoard_id: subBoard_id,
				xPos: xPos,
				yPos: yPos,
				turn: turn,
			},
		};

		try {
			console.log("Player Action on", requestData.data.turn);
			this.socketManager.emitUserGameAction(requestData);
			console.log("Player action sent successfully.");
		} catch (error) {
			console.error("Error executing PlayerActionCommand:", error);
		}
	}
}

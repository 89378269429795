import { SocketPlayersIdentity } from "../Constants";

import {
	type LudoSquadType,
	type LudoTurnsType,
	type BoardData,
	type Player,
	LudoSquadVariants,
	LudoTurnsVariants,
} from "../Types";

export function squadSelector(turn: LudoTurnsType): LudoSquadType {
	switch (turn) {
		case LudoTurnsVariants.BLUE:
			return LudoSquadVariants.BLUE;
		case LudoTurnsVariants.GREEN:
			return LudoSquadVariants.GREEN;
		case LudoTurnsVariants.RED:
			return LudoSquadVariants.RED;
		case LudoTurnsVariants.YELLOW:
			return LudoSquadVariants.YELLOW;

		default:
			return LudoSquadVariants.NONE;
	}
}
export function SocketStartPointToUnityTurn(
	startPoint: number | undefined
): LudoTurnsVariants {
	switch (startPoint) {
		case 0:
			return LudoTurnsVariants.BLUE;
		case 26:
			return LudoTurnsVariants.GREEN;
		case 13:
			return LudoTurnsVariants.RED;
		case 39:
			return LudoTurnsVariants.YELLOW;

		default:
			return LudoTurnsVariants.NONE;
	}
}
export function socketPlayersToUnitySquads(
	startPoint: number
): LudoSquadType | undefined {
	return SocketPlayersIdentity[startPoint] ?? undefined;
}
export const SocketMatchDataToBoardData = (socketData: BoardData) => {
	let currentPlayer: Player | undefined;
	const data: BoardData = {
		...socketData,
		players: socketData.players.map((player) => {
			if (player.playerId === socketData.current_user_id) {
				currentPlayer = player;
			}

			return {
				...player,
				squad: socketPlayersToUnitySquads(player.startPoint),
			};
		}),
		currentPlayerColor: SocketStartPointToUnityTurn(currentPlayer?.startPoint),
	};
	return data;
};

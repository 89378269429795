import {
	type GameModes,
	type gameParameters,
	MatchErrorType,
} from "@/types/global";
import type { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";

export const GameValidateSearch = (
	search: Record<string, unknown>
): gameParameters => {
	// validate and parse the search params into a typed state
	const clientId = (search["clientId"] as string)?.toString();
	const keyName = (search["keyName"] as string)?.toString();
	const gameMode = search["gameMode"] as GameModes;

	return {
		clientId,
		keyName,
		gameMode,
	};
};
export const sendMessageToReactNative = (action: string, data: unknown) => {
	if (window?.ReactNativeWebView) {
		window.ReactNativeWebView.postMessage(
			JSON.stringify({
				action,
				data,
			})
		);
	}
};
export const sendUnityProfileToReactNative = (
	...[userId]: Array<ReactUnityEventParameter>
) => {
	sendMessageToReactNative("profileClicked", {
		userId,
	});
};
export const SocketErrorToReactNativeMessage = (errorType: MatchErrorType) => {
	switch (errorType) {
		case MatchErrorType.MATCH_OVER:
			sendMessageToReactNative("endGame", {});
			break;
		case MatchErrorType.INVALID_MATCH:
			sendMessageToReactNative("matchInvalid", {});
			break;
		case MatchErrorType.GAME_DOES_NOT_EXIST:
			sendMessageToReactNative("matchInvalid", {});
			break;

		default:
			sendMessageToReactNative("matchInvalid", {});
			break;
	}
	console.error("reconnect error :", errorType);
};

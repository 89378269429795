import { useRef, forwardRef, useLayoutEffect } from "react";
import Phaser, { Game } from "phaser";
import GameScene from "./Scenes/GameScene";
import PreLoader from "./Scenes/PreLoader";
import EventHandler from "../GameServices/EventServices/EventHandler";

interface GameConfig extends Phaser.Types.Core.GameConfig {
	audio: {
		disableWebAudio: boolean;
		unlock: boolean;
	};
}
export interface IRefPhaserGame {
	game: Phaser.Game | null;
	scene: Phaser.Scene | null;
}

interface GameViewProps {
	matchID: string;
	token: string;
	gameMode: string;
	lobbyId?: string; // Made optional since it's only required for 'join-lobby' mode
}
const gameConfig: GameConfig = {
	width: "100%",
	height: "100%",
	type: Phaser.AUTO,
	backgroundColor: "#CAD9C6",
	scene: [PreLoader, GameScene],
	scale: {
		mode: Phaser.Scale.FIT,
		autoCenter: Phaser.Scale.CENTER_BOTH,
	},
	audio: {
		disableWebAudio: false,
		unlock: false,
	},
	parent: "game-container",
};
const GameView = forwardRef<IRefPhaserGame, GameViewProps>(
	({ matchID, token, gameMode, lobbyId }) => {
		const gameInstanceRef = useRef<Phaser.Game | null>(null);

		const eventHandler = EventHandler.getInstance();

		useLayoutEffect(() => {
			gameInstanceRef.current = new Game({
				...gameConfig,
				parent: "game-container",
			});
			// Initialize event handler with match ID and token
			eventHandler.setMatchIdAndToken(matchID, token);
			eventHandler.setGameMode(gameMode);

			// Handle 'join-lobby' mode
			if (gameMode === "join-lobby") {
				if (!lobbyId) {
					console.error("Lobby ID is required for 'join-lobby' mode.");
				} else {
					eventHandler.setLobbyId(lobbyId);
				}
			}

			// Cleanup logic when the component unmounts
			return () => {
				if (gameInstanceRef.current) {
					gameInstanceRef.current.destroy(true);
					gameInstanceRef.current = null;
				}
			};
		}, [matchID, token, gameMode, lobbyId]);

		return <div id="game-container"></div>;
	}
);

export default GameView;

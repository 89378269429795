export type BoardConfig = {
	mapSize: number;
	boardSize: number;
	boardColors: number[];
	xColor: string;
	oColor: string;
	separatorLineColor: number;
	cellBorderColor: number;
};

export const board: BoardConfig = {
	mapSize: 3,
	boardSize: 3,
	boardColors: [0xeed5c1, 0xeed5c1],
	xColor: "#d93d04",
	oColor: "#04b4d9",
	separatorLineColor: 0xa68c73,
	cellBorderColor: 0xbbc0b9,
};

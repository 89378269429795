import { useRef, useState } from "react";
import { type IRefPhaserGame, ChessGame } from "./GameView/ChessGame";
import { useParams } from "@tanstack/react-router";
import GameProgressBar from "./GameProgressBar";

function Chess() {
	const { matchID, token } = useParams({
		from: "/chess/$token/$matchID",
	});
	// const { clientId, gameMode } = useSearch({
	// 	from: "/backgammon/$token/$matchID",
	// });

	const phaserRef = useRef<IRefPhaserGame | null>(null);
	const [loadPercentage, setLoadPercentage] = useState<number>(0);

	const handleProgress = (progress: number): void => {
		setLoadPercentage(Math.round(progress * 100)); // Convert to percentage
	};

	return (
		<>
			{loadPercentage !== 100 && <GameProgressBar percent={loadPercentage} />}

			<ChessGame
				ref={phaserRef}
				clientId="10"
				matchID={matchID}
				token={token}
				onProgress={handleProgress}
			/>
		</>
	);
}

export default Chess;

import type GamePresenter from "../GamePresenter";
import type { ChessBoardData } from "../interfaces";

export default class SetProfilesCommand {
	private readonly presenter: GamePresenter;
	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(boardData: ChessBoardData): void {
		this.presenter.setProfile(
			boardData.players_profiles,
			boardData.current_user_id
		);
	}
}

export class SuperTicTacToe {
	private readonly boardSize: number; // Size of the main board
	private readonly subBoardSize: number; // Size of each sub-board
	private mainBoard: Array<Array<Array<Array<string>>>>; // 4D array for the main board
	private mainBoardWinners: Array<Array<string | null>>; // Tracks winners for sub-boards
	private activeSubBoard: [number, number] | null; // The currently active sub-board
	public currentPlayer: string; // Current player's turn ('X' or 'O')

	public constructor(boardSize = 3, subBoardSize = 3) {
		this.boardSize = boardSize; // Initialize board size
		this.subBoardSize = subBoardSize; // Initialize sub-board size
		this.mainBoard = Array.from({ length: boardSize }, () =>
			Array.from({ length: boardSize }, () => this.createSubBoard())
		);
		this.mainBoardWinners = Array.from({ length: boardSize }, () =>
			Array(boardSize).fill(null)
		);
		this.activeSubBoard = null; // No active sub-board initially
		this.currentPlayer = "X"; // Starting player
	}

	private createSubBoard(): Array<Array<string>> {
		return Array.from({ length: this.subBoardSize }, () =>
			Array(this.subBoardSize).fill("")
		);
	}

	playPrevMoves(
		mainRow: number,
		mainCol: number,
		subRow: number,
		subCol: number,
		turn: string
	): void {
		const subBoard = this.mainBoard[mainRow]![mainCol]!;
		subBoard[subRow]![subCol]! = turn.toUpperCase();
		const winner = this.checkWinner(subBoard, subRow, subCol);
		if (winner) {
			this.mainBoardWinners[mainRow]![mainCol]! = winner;
		} else if (this.isSubBoardDraw(subBoard)) {
			this.mainBoardWinners[mainRow]![mainCol]! = "D"; 
		}
	}

	play(
		mainRow: number,
		mainCol: number,
		subRow: number,
		subCol: number,
		turn: string
	): string | null {
		if (
			this.activeSubBoard &&
			(mainRow !== this.activeSubBoard[0] || mainCol !== this.activeSubBoard[1])
		) {
			return `You must play in the sub-board at (${this.activeSubBoard[0]}, ${this.activeSubBoard[1]})`;
		}

		const subBoard = this.mainBoard[mainRow]![mainCol]!;
		if (subBoard[subRow]![subCol]! !== "") {
			return "Cell is already occupied";
		}

		subBoard[subRow]![subCol]! = turn.toUpperCase();

		const winner = this.checkWinner(subBoard, subRow, subCol);
		if (winner) {
			this.mainBoardWinners[mainRow]![mainCol]! = winner;
		} else if (this.isSubBoardDraw(subBoard)) {
			this.mainBoardWinners[mainRow]![mainCol]! = "D"; // Mark as draw
		}

		const mainBoardWinner = this.checkWinner(
			this.mainBoardWinners,
			mainRow,
			mainCol
		);
		if (mainBoardWinner) {
			return `${mainBoardWinner} wins the game!`;
		}

		if (this.isMainBoardDraw()) {
			return "The game is a draw!";
		}

		this.activeSubBoard = [subRow, subCol];
		if (
			this.mainBoardWinners[subRow]![subCol]! !== null &&
			this.mainBoardWinners[subRow]![subCol]! !== ""
		) {
			this.activeSubBoard = null; // Allow any move if sub-board is complete
		}

		//this.currentPlayer = this.currentPlayer === "X" ? "O" : "X";
		return null;
	}

	private checkWinner(
		board: Array<Array<string | null>>,
		row: number,
		col: number
	): string | null {
		const player = board[row]![col]!;
		if (!player || player === "D") return null;

		const directions = [
			[0, 1], // Horizontal
			[1, 0], // Vertical
			[1, 1], // Diagonal top-left to bottom-right
			[1, -1], // Diagonal top-right to bottom-left
		];

		for (const [rowDir, colDir] of directions) {
			let count = 1;
			count += this.countInDirection(board, row, col, rowDir!, colDir!, player);
			count += this.countInDirection(
				board,
				row,
				col,
				-rowDir!,
				-colDir!,
				player
			);
			if (count >= this.subBoardSize) {
				return player;
			}
		}
		return null;
	}

	private countInDirection(
		board: Array<Array<string | null>>,
		row: number,
		col: number,
		rowDir: number,
		colDir: number,
		player: string
	): number {
		let count = 0;
		let r = row + rowDir;
		let c = col + colDir;
		while (
			r >= 0 &&
			r < board.length &&
			c >= 0 &&
			c < board.length &&
			board[r]![c]! === player
		) {
			count++;
			r += rowDir;
			c += colDir;
		}
		return count;
	}

	private isSubBoardDraw(subBoard: Array<Array<string>>): boolean {
		return subBoard.flat().every((cell) => cell !== "");
	}

	private isMainBoardDraw(): boolean {
		return this.mainBoardWinners.flat().every((cell) => cell !== null);
	}

	getBoard(): Array<Array<Array<Array<string>>>> {
		return this.mainBoard;
	}

	getMainBoardWinners(): Array<Array<string | null>> {
		return this.mainBoardWinners;
	}

	getWinnerWithLine(boardData: any): {
		winner: string | null;
		line: [number, number][] | null;
	} {
		const board = boardData;
		const size = this.boardSize;

		for (let i = 0; i < size; i++) {
			const player = board[i]![0]!;
			if (player && board[i]!.every((cell: any) => cell === player)) {
				return {
					winner: player,
					line: Array.from({ length: size }, (_, j) => [i, j]),
				};
			}
		}

		for (let j = 0; j < size; j++) {
			const player = board[0]![j]!;
			if (player && board.every((row: any[]) => row[j] === player)) {
				return {
					winner: player,
					line: Array.from({ length: size }, (_, i) => [i, j]),
				};
			}
		}

		const diagonalTLBRPlayer = board[0]![0]!;
		if (
			diagonalTLBRPlayer &&
			Array.from({ length: size }, (_, i) => board[i]![i]!).every(
				(cell) => cell === diagonalTLBRPlayer
			)
		) {
			return {
				winner: diagonalTLBRPlayer,
				line: Array.from({ length: size }, (_, i) => [i, i]),
			};
		}

		const diagonalTRBLPlayer = board[0]![size - 1]!;
		if (
			diagonalTRBLPlayer &&
			Array.from({ length: size }, (_, i) => board[i]![size - 1 - i]!).every(
				(cell) => cell === diagonalTRBLPlayer
			)
		) {
			return {
				winner: diagonalTRBLPlayer,
				line: Array.from({ length: size }, (_, i) => [i, size - 1 - i]),
			};
		}

		return { winner: null, line: null };
	}

	public getMainBoardWinnerWithLine(): {
		winner: string | null;
		line: [number, number][] | null;
	} {
		return this.getWinnerWithLine(this.mainBoardWinners);
	}

	public getBoardWinnerWithLine(
		i: number,
		j: number
	): {
		winner: string | null;
		line: [number, number][] | null;
	} {
		return this.getWinnerWithLine(this.mainBoard[i]![j]!);
	}
}

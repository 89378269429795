import { GamePresenter } from "../Types";

export default class GameActionErrorCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}
	execute(config: any): void {
		this.presenter.errorInSelectCell(config);
	}
}

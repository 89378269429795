import { sendMessageToReactNative } from "@/helpers";
import type { Profile } from "@/types/global";
import Phaser from "phaser";
import CircleMaskImage from "phaser3-rex-plugins/plugins/circlemaskimage.js";

class AvatarContainer extends Phaser.GameObjects.Container {
	private readonly containerRect!: Phaser.GameObjects.Graphics;
	constructor(
		scene: Phaser.Scene,
		x: number,
		y: number,
		avatarImageKey: string,
		profile: Profile
	) {
		super(scene, x, y);

		// Constants for styling
		const CONTAINER_WIDTH = 150; // Width of the container
		const CONTAINER_HEIGHT = 200; // Height of the container
		const CONTAINER_COLOR = 0x04204d; // Background color (#04204D)
		const BORDER_RADIUS = 12; // Rounded corners
		const PROFILE_SIZE = 120; // Size of the profile image (circle)
		const TEXT_STYLE = {
			fontSize: "16px",
			fontFamily: "Ubuntu",
			color: "#ffffff", // White text
			align: "center",
		};

		// Create the background rounded rectangle (container)
		this.containerRect = scene.add.graphics();
		this.containerRect.fillStyle(CONTAINER_COLOR, 1); // Set fill color and alpha
		this.containerRect.fillRoundedRect(
			x,
			y,
			CONTAINER_WIDTH,
			CONTAINER_HEIGHT,
			BORDER_RADIUS
		);

		this.add(this.containerRect);

		this.scene.load.once("complete", () => {
			const profileImage = new CircleMaskImage(
				scene,
				x + CONTAINER_WIDTH / 2,
				y + 70,
				avatarImageKey
			);
			profileImage.setDisplaySize(PROFILE_SIZE, PROFILE_SIZE); // Set size
			profileImage.setOrigin(0.5, 0.5);
			this.add(profileImage);
		});

		// Add the username text
		const usernameText = this.createEllipsisText(
			this.scene,
			x + CONTAINER_WIDTH / 2,
			y + CONTAINER_HEIGHT - 30,
			profile?.full_name ?? "",
			TEXT_STYLE,
			CONTAINER_WIDTH - 20
		); // Position below center
		usernameText.setOrigin(0.5, 0.5); // Center align text
		this.add(usernameText);
		this.setScale(0.7);

		// Add the container to the scene
		scene.add.existing(this);
		// Enable interactivity
		this.setInteractive(
			new Phaser.Geom.Rectangle(x, y, CONTAINER_WIDTH, CONTAINER_HEIGHT),
			Phaser.Geom.Rectangle.Contains
		);

		// Add a click event listener
		this.on("pointerdown", () => {
			sendMessageToReactNative("profileClicked", {
				userId: profile.user_id,
			});
		});
	}
	private createEllipsisText(
		scene: Phaser.Scene,
		x: number,
		y: number,
		text: string,
		style: Phaser.Types.GameObjects.Text.TextStyle,
		maxWidth: number
	): Phaser.GameObjects.Text {
		const temporaryText = scene.add.text(0, 0, text, style); // Temporary text for measuring
		let truncatedText = text;

		// Check if the text exceeds the maximum width
		while (temporaryText.width > maxWidth && truncatedText.length > 0) {
			truncatedText = truncatedText.slice(0, -1); // Remove one character at a time
			temporaryText.setText(truncatedText + "..."); // Add ellipsis
		}

		// Destroy the temporary text
		temporaryText.destroy();

		// Create the final text object with the truncated text
		return scene.add.text(
			x,
			y,
			truncatedText + (truncatedText === text ? "" : "..."),
			style
		);
	}

	setTurn(active: boolean): void {
		// Clear the previous graphics
		this.containerRect.clear();

		// Redraw the container with or without the stroke
		const CONTAINER_WIDTH = 150; // Width of the container
		const CONTAINER_HEIGHT = 200; // Height of the container
		const CONTAINER_COLOR = 0x04204d; // Background color (#04204D)
		const BORDER_RADIUS = 12; // Rounded corners
		const STROKE_COLOR = 0x30b80e; // Green stroke color (#30B80E)
		const STROKE_THICKNESS = 4; // Stroke thickness

		// Fill the background
		this.containerRect.fillStyle(CONTAINER_COLOR, 1);
		this.containerRect.fillRoundedRect(
			this.x,
			this.y,
			CONTAINER_WIDTH,
			CONTAINER_HEIGHT,
			BORDER_RADIUS
		);

		// Add the stroke if active
		if (active) {
			this.containerRect.lineStyle(STROKE_THICKNESS, STROKE_COLOR, 1);
			this.containerRect.strokeRoundedRect(
				this.x,
				this.y,
				CONTAINER_WIDTH,
				CONTAINER_HEIGHT,
				BORDER_RADIUS
			);
		}
	}
}

export default AvatarContainer;

import GamePresenter from "../GamePresenter/GamePresenter";
import { board } from "../Constants/Configuration/BoardConfig";
import { EventHandler, GameConfig } from "../Types";

export default class CheckSubBoardCommand {
	private eventHandler: EventHandler;
	private presenter: GamePresenter;

	constructor(eventHandler: EventHandler, presenter: GamePresenter) {
		this.eventHandler = eventHandler;
		this.presenter = presenter;
	}

	execute(config: GameConfig): void {
		const subPos = this.presenter.convertIndexToMatrix(
			config.subBoard_id!,
			board.boardSize
		);
		this.checkSubBoardWinner(subPos.row, subPos.col);
	}

	private checkSubBoardWinner(xSub: number, ySub: number): void {
		const winner = this.presenter.game.getMainBoardWinners()[xSub]![ySub]!;
		console.log("Current Board:");
		console.log(this.presenter.game.getBoard());
		if (winner) {
			this.eventHandler.callEvent("subBoardWin", {
				xo: winner.toLowerCase(),
				x: xSub,
				y: ySub,
			});
			this.checkMainBoardWinner();
		}
	}

	private checkMainBoardWinner(): void {
		const result = this.presenter.game.getMainBoardWinnerWithLine();

		if (result.winner && result.winner.toLowerCase() !== "d") {
			this.presenter.highlightWinLine(result);
			this.presenter.gameComplete();
		}
		const isComplete = this.isGameComplete();
		if (isComplete) this.presenter.gameComplete();
	}

	private isGameComplete(): boolean {
		const mainBoardWinner = this.presenter.game.getMainBoardWinners();

		for (let i = 0; i < mainBoardWinner.length; i++) {
			for (let j = 0; j < mainBoardWinner.length; j++) {
				const subGridWinner = mainBoardWinner[i]![j]!;
				if (!subGridWinner) return false;
			}
		}
		return true;
	}
}

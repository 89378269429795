import oImage from "@/assets/games/TicTacToe/o.png";
import xImage from "@/assets/games/TicTacToe/x.png";
import click from "@/assets/games/TicTacToe/click.wav";
import board from "@/assets/games/TicTacToe/board.png";
import heart from "@/assets/games/TicTacToe/heart.png";
import xWin from "@/assets/games/TicTacToe/xWin.png";
import oWin from "@/assets/games/TicTacToe/oWin.png";
import profile from "@/assets/games/TicTacToe/profile.png";
import blueLamp from "@/assets/games/TicTacToe/blueLamp.png";
import redLamp from "@/assets/games/TicTacToe/redLamp.png";
import username from "@/assets/games/TicTacToe/Username.png";
import dWin from "@/assets/games/TicTacToe/dWin.png";
import textFont from "@/assets/games/TicTacToe/ChildHood.otf";

export type Asset = {
	key: string;
	path: string;
};

export const assets: Array<Asset> = [
	{ key: "x", path: xImage },
	{ key: "o", path: oImage },
	{ key: "click", path: click },
	{ key: "board", path: board },
	{ key: "heart", path: heart },
	{ key: "xWin", path: xWin },
	{ key: "oWin", path: oWin },
	{ key: "profile", path: profile },
	{ key: "blueLamp", path: blueLamp },
	{ key: "redLamp", path: redLamp },
	{ key: "username", path: username },
	{ key: "dWin", path: dWin },
	{ key: "textFont", path: textFont },
];

/* eslint-disable camelcase */
import { MATCH_URL } from "@/Constants/config";
import { SocketErrorToReactNativeMessage } from "@/helpers";
import type { MatchErrorType } from "@/types/global";
import { useCallback, useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";

interface Props<T> {
	token: string;
	matchID: string;
	selector: (data: T) => T;
}
function UseSocket<T>({ matchID, selector, token }: Props<T>) {
	const [boardData, setBoardData] = useState<T | undefined>();
	const [isConnected, setIsConnected] = useState(false);

	const matchSocket = useMemo(
		() =>
			io(MATCH_URL, {
				path: "/socket.io",
				transports: ["websocket"],
				// autoConnect: false,
				auth: {
					token: `Access-Token=${token}`,
				},
				reconnection: true,
				reconnectionAttempts: 5,
				reconnectionDelay: 1000,
				query: {
					EIO: "4",
				},
			}),
		[token]
	);
	type ReconnectResponse = {
		success: boolean;
		data: T;
		meta: {
			error_type: MatchErrorType;
			server_time: Date;
		};
	};
	const reconnect = useCallback(() => {
		matchSocket.emit(
			"reconnect",
			{
				match_id: matchID,
			},
			(response: ReconnectResponse) => {
				if (!response?.success) {
					SocketErrorToReactNativeMessage(response.meta.error_type);
					throw Error(response.meta.error_type);
				} else {
					const data = selector(response.data);
					setBoardData(data);
					console.log("board Data", data);
				}
			}
		);
	}, [matchSocket, matchID]);
	useEffect(() => {
		function onConnect() {
			setIsConnected(true);
			console.log("connected");
		}
		function onDisconnect() {
			setIsConnected(false);
			setBoardData(undefined);
			console.log("socket disconnected");
		}

		matchSocket.on("disconnect", onDisconnect);
		matchSocket.on("connect", onConnect);

		matchSocket.on("global_error", (data) => {
			console.log(data);
		});

		return () => {
			//	matchSocket.off();
			matchSocket.disconnect();
		};
	}, [matchSocket]);

	const connect = () => {
		matchSocket.connect();
	};
	const boardCleaner = () => {
		setBoardData(undefined);
	};

	return {
		matchSocket,
		boardData,
		connect,
		reconnect,
		isConnected,
		boardCleaner,
	};
}
export default UseSocket;

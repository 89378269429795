import { type Move, Chess, type Square } from "chess.js";

import type MainBoardScene from "../scenes/MainBoard";
import type { ChessPlayer } from "../interfaces";
import type { Profile } from "@/types/global";

type CapturedPieces = {
	p: number; // Pawns
	n: number; // Knights
	b: number; // Bishops
	r: number; // Rooks
	q: number; // Queens
};
export default class GamePresenter {
	private readonly scene: MainBoardScene;
	public currentFen = "";
	public chessGame = new Chess();
	public CurrentPlayer?: ChessPlayer;
	public CurrentPlayerProfile?: Profile;
	public otherPlayer?: ChessPlayer;
	public otherPlayerProfile?: Profile;

	constructor(view: MainBoardScene) {
		this.scene = view;
	}
	renderBoard(fen: string): void {
		this.currentFen = fen;
		this.chessGame = new Chess(fen);
		this.scene.setupBoard(this.CurrentPlayer?.color === "black");
	}
	changeTurn(): void {
		const turn = this.getTurn() == "b" ? "black" : "white";
		if (this.CurrentPlayer?.color === turn) {
			this.scene.currentAvatar.setTurn(true);
			this.scene.otherAvatar.setTurn(false);
		} else {
			this.scene.currentAvatar.setTurn(false);
			this.scene.otherAvatar.setTurn(true);
		}
	}
	movePice(gameMove: Move | string) {
		const move = this.chessGame.move(gameMove);
		this.scene.eventHandler.callEvent("PiceMove", move);
	}
	setPlayers(players: Array<ChessPlayer>, currentUserId: number) {
		this.CurrentPlayer = players.find(
			(player) => player.playerId === currentUserId
		);
		this.otherPlayer = players.find(
			(player) => player.playerId !== currentUserId
		);
	}
	setProfile(players: Array<Profile>, currentUserId: number) {
		this.CurrentPlayerProfile = players.find(
			(player) => player.user_id === currentUserId
		);
		this.otherPlayerProfile = players.find(
			(player) => player.user_id !== currentUserId
		);
		this.scene.setupAvatars();
	}
	getPossibleMovements(square: Square) {
		console.log(square);
	}

	getCapturedPieces() {
		// Define the starting FEN for a standard chess game
		const startingFEN =
			"rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

		// Initialize two chess instances: one for the starting position, one for the current position
		const startingChess = new Chess(startingFEN);
		const currentChess = new Chess(this.currentFen);

		// Helper function to count pieces on the board
		function countPieces(chessInstance: Chess): {
			w: CapturedPieces;
			b: CapturedPieces;
		} {
			const pieceCounts: { w: CapturedPieces; b: CapturedPieces } = {
				w: { p: 0, n: 0, b: 0, r: 0, q: 0 },
				b: { p: 0, n: 0, b: 0, r: 0, q: 0 },
			};
			const pieces = chessInstance.board().flat(); // Get the board as a flat array

			pieces.forEach((piece) => {
				if (piece) {
					const color = piece.color as "w" | "b";
					const type = piece.type as keyof CapturedPieces;
					pieceCounts[color][type]++;
				}
			});

			return pieceCounts;
		}

		// Count pieces in both the starting and current positions
		const startingPieceCounts = countPieces(startingChess);
		const currentPieceCounts = countPieces(currentChess);

		// Determine captured pieces
		const captured: { w: CapturedPieces; b: CapturedPieces } = {
			w: { p: 0, n: 0, b: 0, r: 0, q: 0 },
			b: { p: 0, n: 0, b: 0, r: 0, q: 0 },
		};

		// Compare piece counts for all piece types
		["w", "b"].forEach((color) => {
			const colorKey = color as keyof typeof captured; // Ensure `color` is typed correctly
			for (const type in captured.w) {
				const key = type as keyof CapturedPieces; // Explicitly type `key`
				const startingCount = startingPieceCounts[colorKey][key];
				const currentCount = currentPieceCounts[colorKey][key];

				captured[colorKey][key] = startingCount - currentCount;
			}
		});

		return captured;
	}
	getTurn() {
		return this.chessGame.turn();
	}
}

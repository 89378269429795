export interface Profile {
	user_id: number;
	username: string;
	full_name?: string;
	profile_image: string;
}
export interface Meta {
	server_time: Date;
}
export interface MatchData<T> {
	success: boolean;
	data: T;
	meta: Meta;
}
export enum GameModes {
	PLAYER = "player",
	SPECTATOR = "spectator",
}
export type gameParameters = {
	keyName: string;
	clientId: string;
	gameMode: `${GameModes}`;
};
export interface UnityGamePath {
	LOADER_URL: string;
	DATA_URL: string;
	FRAMEWORK_URL: string;
	CODE_URL: string;
}
export enum MatchErrorType {
	COIN_NOT_ENOUGH = "coinNotEnough",
	ACTIVE_MATCH = "activeMatch",
	ACTIVE_LOBBY = "activeLobby",
	INVALID_MATCH = "invalidMatch",
	LOBBY_DOES_NOT_EXIST = "lobbyDoesNotExist",
	GAME_DOES_NOT_EXIST = "gameDoesNotExist",
	MATCH_OVER = "matchIsOver",
}
export type ReconnectResponse<T> = {
	success: boolean;
	data: T;
	meta: {
		error_type: MatchErrorType;
		server_time: Date;
	};
};

import Phaser from "phaser";
import GameConfig from "../../Constants/GameConfig";
import EventHandler from "../../GameServices/EventServices/EventHandler";
import type GamePresenter from "../../GamePresenter/GamePresenter";
import type { WiningConditions } from "../../Constants/Configuration/WiningConditionConfig";
import type { Cell, PlayerInfo } from "../../Types";

class GameScene extends Phaser.Scene {
	public gameConfig: GameConfig;
	public eventHandler: EventHandler;

	public matchID!: string;
	public token!: string;
	private readonly presenter: GamePresenter;

	// Game configuration properties
	private mapSize: number;
	private boardSize: number;
	private boardColors: Array<number>;
	private xColor: string;
	private oColor: string;
	private separatorLineColor: number;
	private oImageName: string | undefined;
	private winingCondition: WiningConditions | null;
	private cellBorderColor: number;

	// Game States
	public actualBoardSize: number | null;
	public actualCellSize: number | null;
	public playerTurn: string;

	private clickSound: Phaser.Sound.BaseSound | null;
	private turnText: Phaser.GameObjects.Text | null;
	private timerText: Phaser.GameObjects.Text | null;
	private playerBloods: Array<Phaser.GameObjects.Image>;
	private opponentBloods: Array<Phaser.GameObjects.Image>;

	public timer: number;
	private timerEvent: Phaser.Time.TimerEvent | null;
	public playerName: string;
	private isPlayer: boolean;
	private completedSubGrid: Array<Array<number>>;

	private cells: Array<Array<Array<Array<Cell>>>>;
	private isGamePlayable: boolean;

	constructor() {
		super({ key: "GameScene" });
		this.gameConfig = new GameConfig();
		this.eventHandler = EventHandler.getInstance();
		this.eventHandler.initViewAndPresenter(this);
		this.presenter = this.eventHandler.presenter;
		this.matchID = "";
		this.token = "";
		// Initialize properties
		this.mapSize = 0;
		this.boardSize = 0;
		this.boardColors = [];
		this.xColor = "";
		this.oColor = "";
		this.separatorLineColor = 0;
		this.oImageName = "";
		this.winingCondition = null;
		this.cellBorderColor = 0;

		this.clickSound = null;
		this.turnText = null;
		this.timerText = null;
		this.playerBloods = [];
		this.opponentBloods = [];
		this.actualBoardSize = null;
		this.actualCellSize = null;

		this.timer = 10;
		this.timerEvent = null;
		this.playerName = "X";
		this.isPlayer = true;
		this.playerTurn = "X";
		this.isGamePlayable = true;

		this.completedSubGrid = [];

		this.cells = [];
	}

	init(): void {
		const {
			mapSize,
			boardSize,
			boardColors,
			xColor,
			oColor,
			separatorLineColor,
			winingCondition,
			cellBorderColor,
		} = this.gameConfig;

		// Game configuration properties
		this.mapSize = mapSize;
		this.boardSize = boardSize;
		this.boardColors = boardColors;
		this.xColor = xColor;
		this.oColor = oColor;
		this.separatorLineColor = separatorLineColor;
		this.oImageName = undefined;
		this.winingCondition = winingCondition;
		this.cellBorderColor = cellBorderColor;

		// Game States
		this.clickSound = null;
		this.turnText = null;
		this.timerText = null;
		this.playerBloods = [];
		this.opponentBloods = [];
		this.actualBoardSize = null;
		this.actualCellSize = null;

		this.timer = 10;
		this.timerEvent = null;
		this.isPlayer = true;

		this.token = this.eventHandler.getToken();
		this.matchID = this.eventHandler.getMatchId();
		console.log("Token:", this.token);
		console.log("Match ID:", this.matchID);
		this.completedSubGrid = [];
		this.eventHandler.initializeActions();
	}

	create(): void {
		// setup
		this.setupGameMap();
		this.setupBoardBackGround();
		this.setupSounds();
		this.setupTexts();
		this.deSelectAllSubGrids();

		// events
		this.timerEvent = this.time.addEvent({
			delay: 1000,
			callback: this.updateTimer,
			callbackScope: this,
			loop: true,
			paused: true,
		});
		this.eventHandler.subscribeEvents();

		const gameType = {
			eventName: this.eventHandler.getGameMode(),
		};

		if (gameType.eventName == "join-lobby") {
			const joinLobbyGameType = {
				eventName: this.eventHandler.getGameMode(),
				lobbyId: this.eventHandler.getLobbyId(),
			};
			this.eventHandler.callEvent("startGame", joinLobbyGameType);
		} else {
			this.eventHandler.callEvent("startGame", gameType);
		}

		this.input.once("pointerdown", () => {
			if (this.sound instanceof Phaser.Sound.WebAudioSoundManager) {
				this.sound.context
					.resume()
					.then(() => {
						console.log("Audio context resumed.");
						this.playClickSound();
					})
					.catch((error) => {
						console.warn("Failed to resume audio context:", error);
					});
			} else {
				console.warn("Audio context not available.");
			}
		});
	}

	shutdown(): void {
		this.eventHandler.unSubscribeEvents();
	}

	resetGame = (time: number): void => {
		setTimeout(() => {
			this.cleanMap();
		}, time);
	};

	cleanMap = (): void => {
		for (let x = 0; x < this.gameConfig.boardSize; x++) {
			for (let y = 0; y < this.gameConfig.boardSize; y++) {
				this.cells[x]![y]!.forEach((map) => {
					map.forEach((cell) => {
						cell.xo.destroy();
						cell.winning_xo.destroy();
					});
				});
			}
		}
	};

	/**
	 * Scene Setup
	 */

	setupBoardBackGround = (): void => {
		const size =
			this.actualBoardSize! + this.actualCellSize! + this.actualCellSize! / 2;
		const screenScale = Math.max(
			this.cameras.main.width / 1920,
			this.cameras.main.height / 1080
		);
		this.add
			.image(
				this.cameras.main.centerX,
				this.cameras.main.centerY + this.actualCellSize! / 4 - 5 * screenScale,
				"board"
			)
			.setDisplaySize(size, size + this.actualCellSize! / 4)
			.setDepth(-1);
	};

	setupGameMap = (): void => {
		const { width: boardWidth, height: boardHeight } = this.cameras.main;

		const cellSize = Math.min(
			boardWidth / (this.boardSize * this.mapSize),
			boardHeight / (this.boardSize * this.mapSize)
		);
		const margin = 35;
		const screenScale = Math.min(
			this.cameras.main.width / 1920,
			this.cameras.main.height / 1080
		);
		this.cells = this.createBoard(
			this.boardSize,
			this.mapSize,
			cellSize - margin * screenScale
		);

		this.actualBoardSize =
			(cellSize - margin * screenScale) * (this.boardSize * this.mapSize);
		this.actualCellSize = cellSize - margin * screenScale;
	};

	setupSounds = (): void => {
		this.clickSound = this.sound.add("click");
	};

	setupTexts = (): void => {
		const screenScale = Math.max(
			this.cameras.main.width / 1920,
			this.cameras.main.height / 1080
		);

		const turnTextSize = Math.floor(40 * screenScale);
		const timerTextSize = Math.floor(40 * screenScale);

		this.turnText = this.createText(
			this.cameras.main.centerX,
			this.cameras.main.centerY -
				this.actualBoardSize! / 2 -
				this.actualCellSize! * 3 * screenScale,
			"Tic Tac Toe\n pending to start match",
			turnTextSize
		).setColor("#3c674a");

		this.timerText = this.createText(
			this.cameras.main.centerX,
			this.cameras.main.centerY +
				this.actualBoardSize! / 2 +
				(this.actualCellSize! + 10) * screenScale,
			"00:10",
			timerTextSize
		).setColor("#3c674a");
		this.updateTimerText(this.getTimer());
	};

	configurePlayerDisplay = (
		playerInfo: PlayerInfo,
		opponentInfo: PlayerInfo
	): void => {
		const screenScale = Math.max(
			this.cameras.main.width / 1920,
			this.cameras.main.height / 1080
		);
		const profileSize = Math.floor((this.actualCellSize! + 10) * screenScale);
		const bloodSize = profileSize / 4;

		const createUsernameDisplay = (
			x: number,
			y: number,
			username: string
		): void => {
			const baseFontSize = profileSize / 3;
			let fontSize = baseFontSize;

			const temporaryText = this.add
				.text(0, 0, username, {
					fontFamily: "textFont",
					fontSize: `${fontSize}px`,
					color: "#ffffff",
					align: "center",
					stroke: "#04144c",
					strokeThickness: fontSize * 0.2,
				})
				.setVisible(false);

			let textWidth = temporaryText.width;
			let textHeight = temporaryText.height;

			const paddingX = 20 * screenScale;
			const paddingY = 3 * screenScale;

			let usernameWidth = textWidth + paddingX * 2;
			let usernameHeight = textHeight + paddingY * 2;

			const usernameBg = this.add
				.image(x, y, "username")
				.setDisplaySize(usernameWidth, usernameHeight)
				.setDepth(1);

			while (textWidth > usernameWidth - paddingX * 2 && fontSize > 8) {
				fontSize -= 1;
				temporaryText.setFontSize(fontSize);
				textWidth = temporaryText.width;
				textHeight = temporaryText.height;

				usernameWidth = textWidth + paddingX * 2;
				usernameHeight = textHeight + paddingY * 2;

				usernameBg.setDisplaySize(usernameWidth, usernameHeight);
			}

			this.add
				.text(x, y, username, {
					fontFamily: "textFont",
					fontSize: `${fontSize * 2}px`,
					color: "#ffffff",
					align: "center",
					stroke: "#04144c",
					strokeThickness: fontSize * 0.2,
				})
				.setOrigin(0.5)
				.setDepth(2)
				.setScale(0.5);

			temporaryText.destroy();
		};

		const createProfileImage = (
			x: number,
			y: number,
			image: string
		): Phaser.GameObjects.Image => {
			return this.add
				.image(x, y, image)
				.setDisplaySize(profileSize, profileSize);
		};

		const createBloodIndicators = (
			playerImage: Phaser.GameObjects.Image,
			bloodArray: Array<Phaser.GameObjects.Image>
		): void => {
			for (let index = 0; index < this.winingCondition!.win; index++) {
				const offsetX = (index - 2) * bloodSize;
				const lampY = playerImage.y + profileSize / 2;

				this.add
					.image(playerImage.x - offsetX, lampY, "blueLamp")
					.setDisplaySize(bloodSize, bloodSize)
					.setDepth(2);

				const redLamp = this.add
					.image(playerImage.x - offsetX, lampY, "redLamp")
					.setDisplaySize(bloodSize, bloodSize)
					.setVisible(false)
					.setDepth(2);

				bloodArray.push(redLamp);
			}
		};

		const playerX = this.cameras.main.centerX - this.actualBoardSize! / 3;
		const opponentX = this.cameras.main.centerX + this.actualBoardSize! / 3;
		const profileY =
			this.cameras.main.centerY -
			this.actualBoardSize! / 2 -
			(this.actualCellSize! + 35) * screenScale;

		const playerProfileID = playerInfo.username;
		const opponentProfileID = opponentInfo.username;
		const player = createProfileImage(playerX, profileY, playerProfileID);
		const opponent = createProfileImage(opponentX, profileY, opponentProfileID);

		const playerName = playerInfo.username;
		const opponentName = opponentInfo.username;
		createUsernameDisplay(
			player.x,
			player.y + profileSize / 2 + (bloodSize * 3) / 2,
			playerName
		);
		createUsernameDisplay(
			opponent.x,
			opponent.y + profileSize / 2 + (bloodSize * 3) / 2,
			opponentName
		);

		createBloodIndicators(player, this.playerBloods);
		createBloodIndicators(opponent, this.opponentBloods);

		const turnPosY =
			player.y - profileSize < 0 ? player.y : player.y - profileSize;
		this.turnText!.setPosition(this.cameras.main.centerX, turnPosY);
		this.playerBloods.reverse();
		this.opponentBloods.reverse();
	};

	createText = (
		x: number,
		y: number,
		text: string,
		fontSize: number
	): Phaser.GameObjects.Text => {
		return this.add
			.text(x, y, text, {
				fontFamily: "textFont",
				fontSize: `${fontSize}px`,
				color: "#F2542D",
				stroke: "#ffffff",
				strokeThickness: 8,
				align: "center",
			})
			.setOrigin(0.5);
	};

	/**
	 * Interactive Grid Creation
	 */

	winMap = (xo: string, x: number, y: number): void => {
		this.putXO(xo, x, y, 1, 1, this.actualCellSize! * 3, true);

		this.selectAllSubGrids();
		this.completedSubGrid.push([x, y]);
		const winnerLine = this.presenter.game.getBoardWinnerWithLine(x, y);

		this.cells[x]![y]!.forEach((map, index) => {
			map.forEach((cell, index_) => {
				cell.cell.disableInteractive();
				if (!winnerLine.line) {
					cell.xo.alpha = 0.3;
				} else {
					const isWinnerCell = winnerLine.line.some(
						([winI, winJ]: [number, number]) =>
							winI === index && winJ === index_
					);
					if (isWinnerCell) {
						cell.xo.alpha = 0.3;
					} else {
						cell.xo.destroy();
					}
				}
			});
		});
	};

	createGraphicCell = (
		graphics: Phaser.GameObjects.Graphics,
		x: number,
		y: number,
		width: number,
		height: number,
		color: number,
		cornerRadii: { tl: number; tr: number; br: number; bl: number } = {
			tl: 0,
			tr: 0,
			br: 0,
			bl: 0,
		}
	): Phaser.GameObjects.Graphics => {
		return graphics
			.lineStyle(2, this.cellBorderColor, 1)
			.fillStyle(color, 1)
			.fillRoundedRect(x, y, width, height, cornerRadii)
			.strokeRoundedRect(x, y, width, height, cornerRadii);
	};

	createCell = (
		bix: number,
		biy: number,
		ix: number,
		iy: number,
		x: number,
		y: number,
		size: number,
		color: number,
		cornerRadii: { tl: number; tr: number; br: number; bl: number } = {
			tl: 0,
			tr: 0,
			br: 0,
			bl: 0,
		}
	): Cell => {
		const graphics = this.add.graphics();
		const errorGraphics = this.add.graphics();

		const cell = this.createGraphicCell(
			graphics,
			x - size / 2,
			y - size / 2,
			size,
			size,
			color,
			cornerRadii
		);
		const errorCell = this.createGraphicCell(
			errorGraphics,
			x - size / 2,
			y - size / 2,
			size,
			size,
			0xe63a47,
			cornerRadii
		)
			.setAlpha(0.5)
			.setVisible(false);

		graphics.setInteractive(
			new Phaser.Geom.Rectangle(x - size / 2, y - size / 2, size, size),
			Phaser.Geom.Rectangle.Contains
		);

		(cell as any).alphaLocked = false;
		(cell as any).setCellAlpha = (alpha: number) => {
			if (!(cell as any).alphaLocked) {
				graphics.setAlpha(alpha);
			}
		};
		const xo = this.add.image(x, y, this.oImageName!).setScale(1);
		const winning_xo = this.add
			.image(x, y, this.oImageName!)
			.setScale(1)
			.setDepth(2);

		cell.on("pointerup", () => {
			if (!this.isPlayer || !this.isGamePlayable) return;

			const subBoardID = this.presenter.convertMatrixToIndex(bix, biy);
			const config = {
				subBoard_id: subBoardID,
				xPos: ix,
				yPos: iy,
				turn: this.eventHandler.socketManager?.turnPlayer,
			};
			this.playerTurn = this.presenter.game.currentPlayer.toLowerCase();

			if (
				this.presenter.canPlayerPlacePiece({
					xSubPos: bix,
					ySubPos: biy,
					xPos: ix,
					yPos: iy,
				})
			) {
				(cell as any).setCellAlpha(1);
				this.presenter.putPiece(config);
			} else {
				this.errorInSelectCell(bix, biy, ix, iy);
			}
		});

		cell.on("pointerdown", () => {
			if (!this.isPlayer || !this.isGamePlayable) return;
			(cell as any).setCellAlpha(0.8);
		});

		cell.on("pointerout", () => {
			(cell as any).setCellAlpha(1);
		});

		cell.on("pointerout", () => {
			cell.setAlpha(1);
		});

		return { cell, errorCell, xo, winning_xo };
	};

	createMap = (
		ix: number,
		iy: number,
		xPoint: number,
		yPoint: number,
		size: number,
		cellSize: number
	): Array<Array<Cell>> => {
		const getBorderRadius = (
			ix: number,
			iy: number,
			index: number,
			index_: number
		): { tl: number; tr: number; br: number; bl: number } => {
			const screenScale = Math.max(
				this.cameras.main.width / 1920,
				this.cameras.main.height / 1080
			);
			const borderRadius = 17;

			const isTopLeftCorner =
				ix === 0 && iy === 0 && index === 0 && index_ === 0;
			const isTopRightCorner =
				ix === 0 &&
				iy === this.boardSize - 1 &&
				index === 0 &&
				index_ === this.mapSize - 1;
			const isBottomLeftCorner =
				ix === this.boardSize - 1 &&
				iy === 0 &&
				index === this.mapSize - 1 &&
				index_ === 0;
			const isBottomRightCorner =
				ix === this.boardSize - 1 &&
				iy === this.boardSize - 1 &&
				index === this.mapSize - 1 &&
				index_ === this.mapSize - 1;

			return {
				tl: isTopLeftCorner ? borderRadius * screenScale : 0,
				tr: isTopRightCorner ? borderRadius * screenScale : 0,
				br: isBottomRightCorner ? borderRadius * screenScale : 0,
				bl: isBottomLeftCorner ? borderRadius * screenScale : 0,
			};
		};

		const createSeparatorLine = (
			x: number,
			y: number,
			width: number,
			height: number,
			color: number
		): void => {
			this.add.rectangle(x, y, width, height, color).setDepth(1);
		};

		const screenScale = Math.max(
			this.cameras.main.width / 1920,
			this.cameras.main.height / 1080
		);

		const separateLineThickness = 5;

		if (!(ix === 0 && [0, 1, 2].includes(iy))) {
			createSeparatorLine(
				xPoint + (size * cellSize) / 2,
				yPoint - size / 3 - 2,
				size * cellSize,
				separateLineThickness * screenScale,
				this.separatorLineColor
			);
		}

		const centerX = this.cameras.main.centerX;
		const centerY = this.cameras.main.centerY;
		const mapHeight = this.boardSize * this.mapSize * cellSize;

		createSeparatorLine(
			centerX - (this.mapSize * cellSize) / 2,
			centerY,
			separateLineThickness * screenScale,
			mapHeight,
			this.separatorLineColor
		);

		createSeparatorLine(
			centerX + (this.mapSize * cellSize) / 2 - 2,
			centerY,
			separateLineThickness * screenScale,
			mapHeight,
			this.separatorLineColor
		);

		const map = Array.from({ length: size }, (_, index) =>
			Array.from({ length: size }, (_, index_) => {
				const borderRadius = getBorderRadius(ix, iy, index, index_);
				const cell = this.createCell(
					ix,
					iy,
					index,
					index_,
					xPoint + index_ * cellSize + cellSize / 2,
					yPoint + index * cellSize + cellSize / 2,
					cellSize,
					this.boardColors[(ix + index_ + index + iy) % 2]!,
					borderRadius
				);
				return cell;
			})
		);
		return map;
	};

	createBoard = (
		size: number,
		mapSize: number,
		cellSize: number
	): Array<Array<Array<Array<Cell>>>> => {
		const offsetX = this.cameras.main.centerX - (mapSize * cellSize * size) / 2;
		const offsetY = this.cameras.main.centerY - (mapSize * cellSize * size) / 2;
		const board = Array.from({ length: size }, (_, index) =>
			Array.from({ length: size }, (_, index_) =>
				this.createMap(
					index,
					index_,
					offsetX + index_ * mapSize * cellSize,
					offsetY + index * mapSize * cellSize,
					mapSize,
					cellSize
				)
			)
		);
		return board;
	};

	putXO = (
		xo: string,
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		size: number,
		isWiningXo: boolean = false
	): void => {
		const targetCell = this.cells[x1]![y1]![x2]![y2]!;
		const targetXO = isWiningXo ? targetCell.winning_xo : targetCell.xo;
		const targetSize = isWiningXo ? size : size * 0.7;

		if (xo === "x" || xo === "X") {
			const texture = isWiningXo ? "xWin" : "x";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else if (xo === "o" || xo == "O") {
			const texture = isWiningXo ? "oWin" : "o";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else if (xo === "d" || xo == "D") {
			const texture = "dWin";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else {
			console.warn("Invalid xo");
			return;
		}

		this.animateMarkerScale(targetXO);
		this.playClickSound();
	};

	putXO9x9 = (
		xo: string,
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		size: number,
		isWiningXo: boolean = false
	): void => {
		const targetCell = this.cells[x1]![y1]![x2]![y2]!;
		const targetXO = isWiningXo ? targetCell.winning_xo : targetCell.xo;
		const targetSize = isWiningXo ? size : size * 0.7;

		if (xo === "x" || xo === "X") {
			const texture = isWiningXo ? "xWin" : "x";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else if (xo === "o" || xo == "O") {
			const texture = isWiningXo ? "oWin" : "o";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else if (xo === "d" || xo == "D") {
			const texture = "dWin";
			targetXO
				?.setTexture(texture)
				?.setDepth(1)
				?.setDisplaySize(targetSize, targetSize);
		} else {
			console.warn("Invalid xo");
			return;
		}
	};

	/**
	 * Animations
	 */

	animateFadeTransition = (
		marker: Phaser.GameObjects.Graphics,
		fadeTime: number
	): void => {
		this.tweens.add({
			targets: marker,
			alpha: { from: 0.75, to: 0 },
			duration: fadeTime,
			ease: "linear",
		});
	};

	animateMarkerScale = (marker: Phaser.GameObjects.Image): void => {
		this.tweens.add({
			targets: marker,
			scaleX: marker.scaleX * 0.85,
			scaleY: marker.scaleY * 0.85,
			ease: "Exponential",
			duration: 100,
			yoyo: true,
		});
	};

	animateTurnText = (text: Phaser.GameObjects.Text): void => {
		const scaleX = 1;
		const scaleY = 1;

		this.tweens.killTweensOf(text);
		text.setScale(0.1, 1);
		text.setAlpha(0);

		this.tweens.add({
			targets: text,
			scaleX: scaleX,
			scaleY: scaleY,
			alpha: 1,
			duration: 800,
			ease: "Power2",
		});
	};

	animateWiningMarker = (winMarker: Phaser.GameObjects.Image): void => {
		this.tweens.add({
			targets: winMarker,
			alpha: { from: 1, to: 0 },
			duration: 500,
			ease: "Linear",
			yoyo: true,
			repeat: 1,
		});
	};

	playClickSound() {
		if (!this.clickSound) {
			console.warn("Click sound not initialized yet.");
			return;
		}

		if (this.clickSound.isPlaying) {
			this.clickSound.stop();
		}
		this.clickSound.play({ seek: 0 });
	}

	/**
	 * UI Updates
	 */

	setProfiles(player_profiles: Array<PlayerInfo>): void {
		const playerInfo = player_profiles[1];
		const opponentInfo = player_profiles[0];

		this.load.setCORS("anonymous");

		const cleanPlayerImage = playerInfo!.profile_image.split("?")[0];
		const cleanOpponentImage = opponentInfo!.profile_image.split("?")[0];

		this.load.image(playerInfo!.username, cleanPlayerImage);
		this.load.image(opponentInfo!.username, cleanOpponentImage);

		this.load.once("complete", () => {
			this.configurePlayerDisplay(playerInfo!, opponentInfo!);
		});

		this.load.start();
	}

	removePlayerLife(config: any): void {
		console.log(config);
		const currentLives =
			config.turn_symbol.toLowerCase() == this.playerName.toLowerCase()
				? this.playerBloods
				: this.opponentBloods;
		console.log(
			`playerName: ${this.playerName.toLowerCase()} turn_symbol: ${config.turn_symbol.toLowerCase()}`
		);
		setTimeout(() => {
			console.log(
				"currentLives:",
				currentLives,
				"Length:",
				currentLives.length
			);
			for (let index = 0; index < config.faultCount; index++) {
				if (currentLives[index] && currentLives[index]!.visible) continue;

				if (currentLives[index]) {
					currentLives[index]!.setVisible(true);
				} else {
					console.error(`currentLives[${index}] is undefined`);
				}
			}
		}, config.time);
	}

	updateTurnText = (player: string): void => {
		const playerText = this.playerName == player ? "Your" : "Opponent";
		const color = player == "X" ? this.xColor : this.oColor;

		this.turnText!.setText(`${playerText} Turn`);
		this.turnText!.setColor(color);
		this.animateTurnText(this.turnText!);
	};

	showErrorMessage = (errorMessage: string): void => {
		this.turnText!.setText(errorMessage).setColor("#C70039");
		this.animateTurnText(this.turnText!);
	};

	showGameFinishedText = (): void => {
		this.turnText!.setText("Game Finished");
		this.turnText!.setColor("#3c674a");
		this.animateTurnText(this.turnText!);
	};

	setCanPlay = (isPlayer: boolean): void => {
		this.isPlayer = isPlayer;
	};

	setGamePlayable = (isGamePlayable: boolean): void => {
		this.isGamePlayable = isGamePlayable;
	};

	updateTimerText = (time: number): void => {
		const minute = Math.floor(time / 60);
		const second = Math.floor(time % 60);
		this.timerText!.setText(
			`${minute.toString().padStart(2, "0")}:${second
				.toString()
				.padStart(2, "0")}`
		);
	};

	selectSubGrid = (ix: number, iy: number): void => {
		for (let x = 0; x < this.boardSize; x++) {
			for (let y = 0; y < this.boardSize; y++) {
				if (x == ix && y == iy) {
					this.enableSubgrid(x, y);
					continue;
				}
				this.disableSubGrid(x, y);
			}
		}
	};

	selectAllSubGrids = (): void => {
		for (let x = 0; x < this.boardSize; x++) {
			for (let y = 0; y < this.boardSize; y++) {
				this.enableSubgrid(x, y);

				if (this.isSubGridCompleted(x, y)) {
					this.disableSubGridCells(x, y);
				}
			}
		}
	};

	deSelectAllSubGrids = (): void => {
		for (let x = 0; x < this.boardSize; x++) {
			for (let y = 0; y < this.boardSize; y++) {
				this.disableSubGridCells(x, y);
			}
		}
	};

	pauseTimer = (): void => {
		this.timerEvent!.paused = true;
	};

	restartTimer = (timer: number): void => {
		this.timer = timer;
		this.updateTimerText(this.timer);
		this.timerEvent!.paused = false;
	};

	highlightWinLine = (winLine: Array<Array<number>>): void => {
		if (!winLine) return;

		winLine.forEach((winCoordinate) => {
			if (winCoordinate.length !== 2) return;

			const [x, y] = winCoordinate;
			if (x == undefined || y == undefined) return;

			if (
				x >= 0 &&
				x < this.cells.length &&
				y >= 0 &&
				y < this.cells[x]!.length
			) {
				const marker = this.cells[x]![y]![1]![1]!.winning_xo;
				if (marker) this.animateWiningMarker(marker);
			}
		});
	};

	errorInSelectCell = (
		bix: number,
		biy: number,
		x: number,
		y: number
	): void => {
		const targetCell = this.cells[bix]![biy]![x]![y]!;
		targetCell.errorCell.setVisible(true);
		const fadeTime = 1000;
		this.animateFadeTransition(targetCell.errorCell, fadeTime);
		setTimeout(() => {
			targetCell.errorCell.setVisible(false);
		}, fadeTime);
	};

	/**
	 * UI Helper Functions
	 */

	updateTimer = (): void => {
		this.timer--;
		this.updateTimerText(this.timer);
		if (this.timer <= 0) {
			this.timer = 0;
			this.updateTimerText(this.timer);
		}
	};

	enableSubgrid = (x: number, y: number): void => {
		this.cells[x]![y]!.forEach((map) => {
			map.forEach((cell) => {
				cell.cell.setInteractive();
				(cell.cell as any).alphaLocked = false;
				(cell.cell as any).setCellAlpha(1);
				if (cell.xo) {
					cell.xo.setAlpha(1);
				}
			});
		});
	};

	disableSubGrid = (x: number, y: number): void => {
		if (this.isSubGridCompleted(x, y)) return;

		this.cells[x]![y]!.forEach((map) => {
			map.forEach((cell) => {
				cell.cell.disableInteractive();

				(cell.cell as any).alphaLocked = false;
				(cell.cell as any).setCellAlpha(1);
				(cell.cell as any).alphaLocked = true;

				(cell.cell as any).fillStyle(0xd3d3d3, 1).setAlpha(0.5);

				if (cell.xo) {
					cell.xo.setAlpha(0.8);
				}
			});
		});
	};

	isSubGridCompleted = (x: number, y: number): boolean => {
		return this.completedSubGrid.some(
			([completedX, completedY]) => completedX === x && completedY === y
		);
	};

	disableSubGridCells = (x: number, y: number): void => {
		this.cells[x]![y]!.forEach((row) => {
			row.forEach((cell) => {
				cell.cell.disableInteractive();
				cell.xo.alpha = 0.5;
			});
		});
	};

	getTimer(): number {
		return 25;
	}
}

export default GameScene;

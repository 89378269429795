/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Socket, io } from "socket.io-client";
import { MATCH_URL } from "@/Constants/config";
import {
	sendMessageToReactNative,
	SocketErrorToReactNativeMessage,
} from "@/helpers";
import type { ReconnectResponse } from "@/types/global";
import type EventHandler from "./EventHandler";
import {
	type ChessBoardData,
	ChessSocketEventVariant,
	type SocketActionData,
	type SocketResultData,
	type SocketTurnUpdateData,
} from "../interfaces";

export default class SocketManager {
	public socket: Socket;
	public matchId: string;
	public token: string;
	public playerId: number;
	private readonly eventHandler: EventHandler;
	constructor(matchId: string, token: string, eventHandler: EventHandler) {
		this.matchId = matchId;
		this.token = token;
		this.eventHandler = eventHandler;
		this.playerId = 0;
		this.socket = io(MATCH_URL, {
			path: "/socket.io",
			transports: ["websocket"],
			auth: {
				token: `Access-Token=${this.token}`,
			},
			reconnection: true,
			reconnectionAttempts: 5,
			reconnectionDelay: 1000,
			query: {
				EIO: "4",
			},
		});
	}

	reconnect(): void {
		this.socket.emit(
			"reconnect",
			{
				// eslint-disable-next-line camelcase
				match_id: this.matchId,
			},
			(response: ReconnectResponse<ChessBoardData>) => {
				if (!response?.success) {
					SocketErrorToReactNativeMessage(response.meta.error_type);
					throw Error(response.meta.error_type);
				} else {
					console.log("board Data", response.data);
					this.eventHandler.callEvent("SetPlayers", response.data);
					this.eventHandler.callEvent("SetProfiles", response.data);
					this.eventHandler.callEvent("SpawnBoard", response.data.FEN);
					sendMessageToReactNative("gameStarted", {});
					this.subscribeResponseToCommand();
				}
			}
		);
	}

	connect(): void {
		this.socket.connect();
		this.listenForEvents();
	}

	listenForEvents(): void {
		this.socket.on("connect", () => {
			console.log("Socket connected", this.socket.connected);
			this.reconnect();
		});

		this.socket.on("error", (response) => {
			console.log("error", response);
		});
	}

	subscribeResponseToCommand(): void {
		this.socket.on("game-engine-event", (data: string) => {
			const socketData = JSON.parse(data);
			console.log(socketData);
			switch ((socketData as SocketActionData).action) {
				case ChessSocketEventVariant.PIECE_MOVED_EVENT:
					console.log("move", socketData);
					break;
				case ChessSocketEventVariant.TUNE_UPDATED_EVENT: {
					const turnUpdateData =
						socketData as SocketActionData<SocketTurnUpdateData>;
					this.eventHandler.callEvent("turnUpdate", turnUpdateData.data);
					break;
				}

				case ChessSocketEventVariant.END_GAME: {
					const gameResult = socketData as SocketActionData<SocketResultData>;
					sendMessageToReactNative("endGame", { ...gameResult.data });
					break;
				}

				default:
					break;
			}
		});
	}

	emitUserGameAction(arg: any): void {
		this.socket.emit("user-game-action", arg, (response: any) => {
			console.log("Response from server:", response);
			// its not our turn
			// if (response.success == false) {
			// 	const cellData = {
			// 		xPos: arg.data.xPos,
			// 		yPos: arg.data.yPos,
			// 		subBoard_id: arg.data.subBoard_id,
			// 	};
			// 	//this.eventHandler.callEvent("gameActionError", cellData);
			// }
		});
	}

	unsubscribeEvent(eventName: string): void {
		this.socket.off(eventName);
	}

	unSubscribeAllEvents(): void {
		this.unsubscribeEvent("game-engine-event");
		this.socket.off();
	}
}

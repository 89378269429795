import { GamePresenter } from "../Types";

type Config = {
	newBoard: any;
};

export default class UpdateMapCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(config: Config): void {
		this.presenter.renderBoard(config.newBoard);
	}
}

import GamePresenter from "../../GamePresenter/GamePresenter";
import GameScene from "../../GameView/Scenes/GameScene";
import EventDispatcher from "./EventDispatcher";
import SocketManager from "../../Commands/SocketIOManager";

// Commands
import SpawnBoardCommand from "../../Commands/SpawnBoardCommand";
import StartGameCommand from "../../Commands/StartGameCommand";
import FinishGameCommand from "../../Commands/FinishGameCommand";
import WinGameCommand from "../../Commands/WinGameCommand";
import LoseGameCommand from "../../Commands/LoseGameCommand";
import PlayerActionCommand from "../../Commands/PlayerActionCommand";
import SubBoardWinCommand from "../../Commands/SubBoardWinCommand";
import UpdateMapCommand from "../../Commands/UpdateMapCommand";
import TimeOutCommand from "../../Commands/TimeOutCommand";
import SetProfileInfoCommand from "../../Commands/SetProfileInfoCommand";
import SetPlayerTurnCommand from "../../Commands/SetPlayerTurnCommand";
import CheckSubBoardCommand from "../../Commands/CheckSubBoardCommand";
import AIMoveCommand from "../../Commands/AIMoveCommand";
import StartMatchCommand from "../../Commands/StartMatchCommand";
import SetInitialPlayerTurnCommand from "../../Commands/SetInitialPlayerTurnCommand";
import GameActionErrorCommand from "../../Commands/GameActionErrorCommand";

interface CustomEventWithDetail<T = any> extends Event {
	detail: T;
}

type EventCallback = (event: CustomEventWithDetail) => void;

export default class EventHandler {
	// Singleton instance
	private static _instance: EventHandler;

	// Properties
	public view!: GameScene;
	public presenter!: GamePresenter;
	public matchId: string = "";
	public token: string = "";
	public gameMode: string = "";
	public lobbyId: string = "";
	public socketManager: SocketManager | null = null;

	private commands: Record<
		string,
		{ command: any; callback: EventCallback | null }
	> = {};
	private eventCallbacks: Record<string, EventCallback> = {};

	private constructor() {}

	public static getInstance(): EventHandler {
		if (!EventHandler._instance) {
			EventHandler._instance = new EventHandler();
		}
		return EventHandler._instance;
	}

	// Setters
	setLobbyId(lobbyId: string) {
		this.lobbyId = lobbyId;
	}

	setGameMode(gameMode: string) {
		this.gameMode = gameMode;
	}

	// Getters
	getMatchId(): string {
		return this.matchId;
	}

	getToken(): string {
		return this.token;
	}

	getPlayerId(): number {
		return this.socketManager?.playerId!;
	}

	getLobbyId(): string {
		return this.lobbyId;
	}

	getGameMode(): string {
		return this.gameMode;
	}

	// Helper functions
	setPlayerName(playerName: string) {
		this.presenter.setPlayerName(playerName);
	}

	showErrorMessage(error: string) {
		this.view.showErrorMessage(error);
	}

	// Methods
	initViewAndPresenter(view: GameScene): void {
		this.view = view;
		this.presenter = new GamePresenter(view);
	}

	setMatchIdAndToken(matchID: string, token: string): void {
		this.matchId = matchID;
		this.token = token;
	}

	initializeActions(): void {
		this.socketManager = new SocketManager(this.matchId, this.token, this);

		// Initialize commands
		this.commands = {
			startGame: {
				command: new StartGameCommand(this.socketManager),
				callback: null,
			},
			playerAction: {
				command: new PlayerActionCommand(this, this.socketManager),
				callback: null,
			},
			checkSubBoard: {
				command: new CheckSubBoardCommand(this, this.presenter),
				callback: null,
			},
			subBoardWin: {
				command: new SubBoardWinCommand(this.presenter),
				callback: null,
			},
			updateMap: {
				command: new UpdateMapCommand(this.presenter),
				callback: null,
			},
			updateTurn: {
				command: new SetPlayerTurnCommand(this.presenter),
				callback: null,
			},
			updateProfile: {
				command: new SetProfileInfoCommand(this.presenter, this),
				callback: null,
			},
			timeOut: { command: new TimeOutCommand(this.presenter), callback: null },
			spawnBoard: {
				command: new SpawnBoardCommand(this.presenter, this),
				callback: null,
			},
			finishGame: {
				command: new FinishGameCommand(this.presenter),
				callback: null,
			},
			winGame: { command: new WinGameCommand(), callback: null },
			loseGame: { command: new LoseGameCommand(), callback: null },
			aiMove: { command: new AIMoveCommand(this.presenter), callback: null },
			startMatch: {
				command: new StartMatchCommand(this.view, this.presenter),
				callback: null,
			},
			initialTurn: {
				command: new SetInitialPlayerTurnCommand(this.presenter),
				callback: null,
			},
			gameActionError: {
				command: new GameActionErrorCommand(this.presenter),
				callback: null,
			},
		};
	}

	subscribeEvents(): void {
		for (const [eventName, { command }] of Object.entries(this.commands)) {
			const callback = (event: CustomEventWithDetail) =>
				command.execute(event.detail);
			this.eventCallbacks[eventName] = callback;
			EventDispatcher.addEventListener(eventName, callback);
		}
	}

	unSubscribeEvents(): void {
		for (const [eventName, callback] of Object.entries(this.eventCallbacks)) {
			EventDispatcher.removeEventListener(eventName, callback);
		}
		this.socketManager?.unSubscribeAllEvents();
	}

	addEvent(name: string, action: EventCallback): void {
		EventDispatcher.addEventListener(name, action);
	}

	removeEvent(name: string, action: EventCallback): void {
		EventDispatcher.removeEventListener(name, action);
	}

	callEvent(eventName: string, data?: any): void {
		EventDispatcher.dispatchEvent(new CustomEvent(eventName, { detail: data }));
	}
}

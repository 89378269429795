import type { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import type {
	ActionPayload,
	DisconnectData,
	EventHandler,
	LeaveData,
	QuickPair,
	ReconnectData,
	ReconnectAPIResponse,
} from "../Types";
import { sendMessageToReactNative } from "@/helpers";

export default class SocketManager {
	public socket: Socket;
	public matchId: string;
	public token: string;
	private readonly eventHandler: EventHandler;
	public playerId: number;
	public turnPlayer: string;

	constructor(matchId: string, token: string, eventHandler: EventHandler) {
		this.matchId = matchId;
		this.token = token;
		this.eventHandler = eventHandler;
		this.playerId = 0;
		this.turnPlayer = "X";
		const url = `${import.meta.env["VITE_API_URL"]}/matches`;
		this.socket = io(url, {
			path: "/socket.io",
			transports: ["websocket"],
			auth: {
				token: `Access-Token=${this.token}`,
			},
			reconnection: true,
			reconnectionAttempts: 5,
			reconnectionDelay: 1000,
			query: {
				EIO: "4",
			},
		});
		this.listenForEvents();
		this.subscribeResponseToCommand();
		this.connect();
	}

	gameOptions(eventName: string, eventData?: string): void {
		switch (eventName) {
			case "quick-pair":
				this.quickPair();
				break;
			case "join-lobby":
				this.joinLobby(eventData!);
				break;
			case "create-lobby":
				this.createLobby();
				break;
			case "reconnect":
				this.reconnect();
				break;
			default:
				break;
		}
	}

	quickPair(): void {
		const quickPairData: QuickPair = {
			game_id: 2829,
			players_count: 2,
			entry_cost: 0,
			is_stream_active: true,
		};

		this.socket.emit("quick-pair", quickPairData, (response: any) => {
			if (response["success"] == false) {
				this.matchId = response["meta"]["match"]["match_id"];
				console.log("already has a match", this.matchId);
				this.reconnect();
			} else {
				this.playerId = response["data"]["player_id"];
			}
			console.log("quick-pair", response);
		});
	}

	reconnect(): void {
		const match_id = this.matchId;
		const reconnectData: ReconnectData = {
			match_id,
		};

		this.socket.emit("reconnect", reconnectData, (response: any) => {
			console.log("reconnect", response);
			if (response["success"] === true) {
				this.playerId = response["data"]["current_user_id"];
				sendMessageToReactNative("gameStarted", {});
				const serializedResponse: ReconnectAPIResponse = response;
				this.turnPlayer = serializedResponse.data.players.find(
					(e: { playerId: number }) => e.playerId === this.playerId
				)!.turn_symbol;
				const lastTurn = serializedResponse.data.last_turn;
				const currentTurn = lastTurn.move.turn.toUpperCase() == "X" ? "O" : "X";

				this.eventHandler.setPlayerName(this.turnPlayer.toUpperCase());
				this.eventHandler.callEvent("updateProfile", response);
				this.eventHandler.callEvent("spawnBoard", response);
				this.eventHandler.callEvent("startMatch", currentTurn);
				this.eventHandler.callEvent("initialTurn", {
					move: serializedResponse.data.last_turn.move,
					fullResponse: serializedResponse,
				});
				for (let index = 0; index < 2; index++) {
					const config: any = {
						turn_symbol: response.data.players[index].turn_symbol,
						faultCount: response.data.players[index].faultCount,
						time: 3000,
					};
					this.eventHandler.callEvent("timeOut", config);
				}
			}

			if (response["meta"]["error_type"] === "matchIsOver") {
				this.eventHandler.showErrorMessage(response["meta"]["error_type"]);
				this.disconnect(this.playerId);
			}
		});
	}

	connect(): void {
		this.socket.connect();
	}

	playerAction(actionName: string, playerId: number, moveData: any): void {
		const matchId = this.matchId;
		const actionPayload: ActionPayload = {
			data: {
				matchId,
				actionName,
				playerId,
				data: moveData,
			},
		};
		this.emitUserGameAction(actionPayload);
	}

	disconnect(playerId: number): void {
		const matchId = this.matchId;
		const actionName = "engine.player_disconnect";
		const disconnectData: DisconnectData = {
			matchId,
			playerId,
		};
		const actionPayload: ActionPayload = {
			data: {
				matchId,
				actionName,
				playerId,
				data: disconnectData,
			},
		};

		this.emitUserGameAction(actionPayload);
	}

	leaveGame(playerId: number): void {
		const matchId = this.matchId;
		const action = "engine.player_left";
		const leaveData: LeaveData = {
			matchId,
			playerId,
			action,
		};
		this.emitUserGameAction(leaveData);
	}

	createLobby(): void {
		const initGameData = {
			game_id: 2829,
			players_count: 2,
			entry_cost: 0,
			is_stream_active: true,
		};
		this.socket.emit("create-lobby", initGameData, (response: any) => {
			console.log("create lobby response:", response);
			if (!response.success) {
				this.eventHandler.showErrorMessage(response["meta"]["error_type"]);
				this.reconnect();
				return;
			}
			console.log("lobby_id:", response.data.lobby_id);
			this.playerId = response.data.player_id;
		});
	}

	joinLobby(lobbyId: string): void {
		const lobbyData = {
			lobby_id: lobbyId,
		};
		console.log("want to connect to this lobby id", lobbyId);

		this.socket.emit("join-lobby", lobbyData, (response: any) => {
			console.log("join lobby response:", response);
			if (!response.success) {
				this.eventHandler.showErrorMessage(response["meta"]["error_type"]);
				return;
			}
			this.playerId = response.data.player_id;
		});
	}

	listenForEvents(): void {
		this.socket.on("connect", () => {
			console.log("Connected to server");
			console.log("Socket connected", this.socket.connected);
		});

		this.socket.on("reconnect", (response) => {
			console.log("reconnect", response);
		});

		// this.socket.on("player-joined", (response) => {
		// 	const serializedResponse: ApiResponse = response;
		// 	const playerIds: number[] = serializedResponse.lobby_players.map(
		// 		(player) => player.user_id
		// 	);
		// 	this.matchId = serializedResponse.match_id;
		// 	const currentPlayerIndex = playerIds.findIndex(
		// 		(id) => id === this.playerId
		// 	);
		// 	this.eventHandler.callEvent(
		// 		"initialTurn",
		// 		currentPlayerIndex === 0 ? { turn: "X" } : { turn: "O" }
		// 	);
		// 	console.log(playerIds);
		// });

		this.socket.onAny((eventName, data) => {
			console.log("Received event:", eventName, data);
		});
	}

	subscribeResponseToCommand(): void {
		this.socket.on("game-engine-event", (response) => {
			response = JSON.parse(response);
			console.log(response.action);
			switch (response.action) {
				case "engine.match_setup":
					this.eventHandler.callEvent("updateProfile", response);
					break;
				case "engine.match_start":
					console.log("player_id start match", this.playerId);
					this.turnPlayer = response.data.players.find(
						(e: { playerId: number }) => e.playerId === this.playerId
					).turn_symbol;
					this.eventHandler.setPlayerName(this.turnPlayer.toUpperCase());
					this.eventHandler.callEvent("startMatch");

					break;
				case "XO.turn_timed_out":
					const config: any = {
						turn_symbol: response.data.turn_symbol,
						faultCount: response.data.faultCount,
						time: 500,
					};
					this.eventHandler.callEvent("timeOut", config);
					break;
				case "XO.tune_updated":
					if (response.data.error.length != 0) {
						console.log("Errors in XO.tune_updated", response.data.error);
						break;
					}
					this.eventHandler.callEvent("aiMove", response.data.move);
					this.eventHandler.callEvent("updateTurn", response.data.move);
					break;
				case "engine.match_result":
					this.eventHandler.callEvent("finishGame");
					sendMessageToReactNative("endGame", { ...response.data });
					break;
				case "XO.selectPosition":
					console.log("XO.selectPosition Called");
					break;
				default:
					break;
			}
		});
	}

	emitUserGameAction(arg: any): void {
		this.socket.emit("user-game-action", arg, (response: any) => {
			console.log("Response from server:", response);
			// its not our turn
			if (response.success == false) {
				const cellData = {
					xPos: arg.data.xPos,
					yPos: arg.data.yPos,
					subBoard_id: arg.data.subBoard_id,
				};
				this.eventHandler.callEvent("gameActionError", cellData);
			}
		});
	}

	unsubscribeEvent(eventName: string): void {
		this.socket.off(eventName);
	}

	unSubscribeAllEvents(): void {
		this.unsubscribeEvent("quick-pair");
		this.unsubscribeEvent("player-joined");
		this.unsubscribeEvent("game-engine-event");
	}
}

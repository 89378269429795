import { GameConfig, GamePresenter } from "../Types";

export default class TimeOutCommand {
	private presenter: GamePresenter;

	constructor(presenter: GamePresenter) {
		this.presenter = presenter;
	}

	execute(config: GameConfig): void {
		this.presenter.handleTimeOut(config);
	}
}

import type { UnityGamePath } from "@/types/global";
import { LudoSquadVariants } from "../Types";

export const unityActions = {
	DiceHandler: {
		get changeDiceNumber() {
			return `SetDiceRollNum`;
		},
	},
	playerHandlers: {
		get movePlayer() {
			return `MovePlayer`;
		},
		get hitPlayer() {
			return `PlayerHitHandler`;
		},
	},
	squadHandlers: {
		get ActivePaces() {
			return `ActivePices`;
		},
		get HitPacesByID() {
			return `HitPlayerByID`;
		},
		get WonHandler() {
			return `WonHandler`;
		},
		get ResetSquadTimers() {
			return `ResetSquadTimers`;
		},
	},
	gameHandlers: {
		get changeTurn() {
			return `changeTurn`;
		},
	},
};

export const SocketPlayersIdentity: { [key: number]: LudoSquadVariants } = {
	0: LudoSquadVariants.BLUE,
	13: LudoSquadVariants.RED,
	26: LudoSquadVariants.GREEN,
	39: LudoSquadVariants.YELLOW,
};
export const LUDO_GAME_PATH: UnityGamePath = {
	LOADER_URL: "/games/Ludo-1/ludo.loader.js",
	DATA_URL: "/games/Ludo-1/ludo.data.br",
	FRAMEWORK_URL: "/games/Ludo-1/ludo.framework.js.br",
	CODE_URL: "/games/Ludo-1/ludo.wasm.br",
};

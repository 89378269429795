import ChessBoard from "../Objects/ChessBoard";
import type { PromotionOptionSelected } from "../interfaces";
import ChessPiece from "../Objects/Piece";
import { ONE, ZERO } from "../Helpers/consts";
import SceneKeys from "../Helpers/SceneKeys";
import EventHandler from "../Helpers/EventHandler";
import type GamePresenter from "../GamePresenter";
import AvatarContainer from "../Objects/Avatar";

export default class MainBoardScene extends Phaser.Scene {
	public eventHandler: EventHandler;
	public chessBoard!: ChessBoard;
	private readonly presenter: GamePresenter;
	private currentPlayerTimerEvent!: Phaser.Time.TimerEvent;
	private otherPlayerTimerEvent!: Phaser.Time.TimerEvent;
	public currentAvatar!: AvatarContainer;
	public otherAvatar!: AvatarContainer;
	constructor() {
		super({ key: SceneKeys.MainBoard });
		this.eventHandler = EventHandler.getInstance();
		this.eventHandler.initViewAndPresenter(this);
		this.presenter = this.eventHandler.presenter;
	}

	preload() {
		this.eventHandler.configSocketIo();
	}
	setupBoard(flip: boolean = false) {
		this.time.removeAllEvents();

		this.chessBoard = new ChessBoard(
			this,
			10,
			350,
			this.presenter.currentFen,
			this.presenter,
			this.eventHandler
		);
		const turn = this.presenter.getTurn() === "w" ? "white" : "black";
		let BlackTimeLeft = this.presenter.CurrentPlayer?.remainingTime ?? 300000; // 2 minutes in seconds
		let WhiteTimeLeft = this.presenter.otherPlayer?.remainingTime ?? 300000; // 2 minutes in seconds
		const blackTimerText = this.registry.get(
			"black_timer"
		) as Phaser.GameObjects.Text;
		const whiteTimerText = this.registry.get(
			"white_timer"
		) as Phaser.GameObjects.Text;

		blackTimerText.setText(this.formatTime(BlackTimeLeft));
		whiteTimerText.setText(this.formatTime(WhiteTimeLeft));
		if (turn === this.presenter.CurrentPlayer?.color) {
			this.currentPlayerTimerEvent = this.time.addEvent({
				delay: 100, // 1 second
				callback: () => {
					BlackTimeLeft -= 100;
					const formattedTime = this.formatTime(BlackTimeLeft);

					blackTimerText.setText(formattedTime);

					if (BlackTimeLeft <= 0) {
						console.log("Black Time is up!");
						this.currentPlayerTimerEvent.remove();
						blackTimerText.setText("00:00");
					}
				},
				loop: true,
			});
		} else {
			this.otherPlayerTimerEvent = this.time.addEvent({
				delay: 100, // 1 second
				callback: () => {
					WhiteTimeLeft -= 100;
					const formattedTime = this.formatTime(WhiteTimeLeft);

					whiteTimerText.setText(formattedTime);

					if (WhiteTimeLeft <= 0) {
						console.log("white Time is up!");
						this.otherPlayerTimerEvent.remove();
						whiteTimerText.setText("00:00");
					}
				},
				loop: true,
			});
		}

		if (flip) this.chessBoard.flipBoard();
	}
	setupAvatars() {
		if (
			!this.presenter.CurrentPlayerProfile ||
			!this.presenter.otherPlayerProfile
		)
			return;

		this.load.image(
			"current_player_avatar",
			this.presenter.CurrentPlayerProfile.profile_image
		);
		this.load.image(
			"other_player_avatar",
			this.presenter.otherPlayerProfile.profile_image
		);
		this.currentAvatar = new AvatarContainer(
			this,
			this.cameras.main.x + 20,
			this.cameras.main.y + 70,
			"current_player_avatar",
			this.presenter.CurrentPlayerProfile
		);

		this.otherAvatar = new AvatarContainer(
			this,
			this.cameras.main.x + 140,
			this.cameras.main.y + 70,
			"other_player_avatar",
			this.presenter.otherPlayerProfile
		);
		this.load.start();
	}
	// Helper function to format time in MM:SS format from milliseconds
	private formatTime(milliseconds: number): string {
		const totalSeconds = Math.floor(milliseconds / 1000); // Convert milliseconds to seconds
		const minutes = Math.floor(totalSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (totalSeconds % 60).toString().padStart(2, "0");
		return `${minutes}:${seconds}`;
	}
	create() {
		this.events.once("destroy", () => {
			this.eventHandler.unSubscribeEvents();
			this.time.removeAllEvents();
		});

		// Promotion behavior
		this.events.on(
			"resume",
			(scene: Phaser.Scene, data: PromotionOptionSelected) => {
				this.finishPromotionMove(scene, data);
			},
			this
		);
	}

	finishPromotionMove(
		_scene: Phaser.Scene,
		promotionData: PromotionOptionSelected
	) {
		const currentBoard = this.chessBoard.getBoard();
		const { origin, target, pieceColor, pieceName } = promotionData;
		const sansMoves = this.presenter.chessGame.moves({ square: origin });
		if (pieceName === "close") {
			return;
		}
		const promotionFor = pieceName[ZERO]?.toUpperCase();
		if (!promotionFor) return;
		const pieceType = ChessPiece.pieceTypeFromSymbol(promotionFor);
		const originIndex = ChessBoard.getSquareNumberInBoard(origin);
		const targetIndex = ChessBoard.getSquareNumberInBoard(target);
		const originSquare = currentBoard[originIndex];
		const targetSquare = currentBoard[targetIndex];

		if (!targetSquare || !originSquare) {
			return;
		}

		let mutableFinalMove = `${target}=${promotionFor}`;
		const possibleMoves = sansMoves.filter(
			(move) => move.indexOf(mutableFinalMove) >= ZERO
		);
		if (possibleMoves.length > ONE) {
			possibleMoves.sort((pMoveA, pMoveB) => pMoveB.length - pMoveA.length);
			mutableFinalMove = possibleMoves.shift() ?? mutableFinalMove;
		} else {
			mutableFinalMove = possibleMoves.shift() ?? mutableFinalMove;
		}

		const newChessPiece = new ChessPiece(
			pieceColor | pieceType,
			target,
			targetSquare.rectangle,
			this,
			this.presenter.CurrentPlayer?.color ?? "white",
			() => {}
		);

		if (targetSquare.piece) {
			targetSquare.piece.destroy();
		}

		// Remove piece from current position
		originSquare.piece?.destroy();
		this.chessBoard.removePieceFromCurrentPosition(originSquare.piece!);

		// Set the new position of the piece
		targetSquare.piece = newChessPiece;
		this.chessBoard.add(targetSquare.piece);
		this.chessBoard.setFen(this.presenter.chessGame.fen());
		this.presenter.movePice(mutableFinalMove);
	}
}

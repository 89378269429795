/* eslint-disable no-use-before-define */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
	PiceMoveCommand,
	SelectPiceCommand,
	SetPlayersCommand,
	SetProfilesCommand,
	SpawnBoardCommand,
	TurnUpdateCommand,
} from "../Commands";
import GamePresenter from "../GamePresenter";
import type MainBoardScene from "../scenes/MainBoard";
import { EventBus } from "./EventBus";
import SocketManager from "./SocketManager";

interface CustomEventWithDetail<T = unknown> extends Event {
	detail: T;
}

type EventCallback = (event: CustomEventWithDetail) => void;

export default class EventHandler {
	// Singleton instance
	private static _instance: EventHandler;
	private commands: Record<
		string,
		{
			command: { execute: (detail?: unknown) => void };
			callback: EventCallback | null;
		}
	> = {};
	private eventCallbacks: Record<string, EventCallback> = {};
	// Properties
	public view!: MainBoardScene;
	public presenter!: GamePresenter;
	public currentUserId = 0;
	public matchId: string = "";
	public token: string = "";
	public gameMode: string = "";
	public lobbyId: string = "";
	public socketManager: SocketManager | null = null;

	public static getInstance(): EventHandler {
		if (!EventHandler._instance) {
			EventHandler._instance = new EventHandler();
		}
		return EventHandler._instance;
	}
	initViewAndPresenter(view: MainBoardScene): void {
		this.view = view;
		this.presenter = new GamePresenter(view);
	}
	setGameMode(gameMode: string) {
		this.gameMode = gameMode;
	}

	// Getters
	getMatchId(): string {
		return this.matchId;
	}

	getToken(): string {
		return this.token;
	}

	getGameMode(): string {
		return this.gameMode;
	}

	setMatchIdAndToken(matchID: string, token: string): void {
		this.matchId = matchID;
		this.token = token;
	}
	configSocketIo() {
		this.socketManager = new SocketManager(this.matchId, this.token, this);
		this.socketManager.connect();
		this.configCommands();
	}
	configCommands() {
		// Initialize commands
		this.commands = {
			SpawnBoard: {
				command: new SpawnBoardCommand(this.presenter),
				callback: null,
			},
			PiceMove: {
				command: new PiceMoveCommand(this, this.socketManager),
				callback: null,
			},
			turnUpdate: {
				command: new TurnUpdateCommand(this.presenter),
				callback: null,
			},
			SetPlayers: {
				command: new SetPlayersCommand(this, this.presenter),
				callback: null,
			},
			SelectPice: {
				command: new SelectPiceCommand(this.presenter),
				callback: null,
			},
			SetProfiles: {
				command: new SetProfilesCommand(this.presenter),
				callback: null,
			},
		};
		this.subscribeEvents();
	}
	subscribeEvents(): void {
		for (const [eventName, { command }] of Object.entries(this.commands)) {
			const callback = (event: CustomEventWithDetail) => {
				command.execute(event);
			};
			this.eventCallbacks[eventName] = callback;
			EventBus.on(eventName, callback);
		}
	}
	callEvent(eventName: string, data?: unknown): void {
		EventBus.emit(eventName, data);
	}

	unSubscribeEvents(): void {
		for (const [eventName, callback] of Object.entries(this.eventCallbacks)) {
			EventBus.off(eventName, callback);
		}
		this.socketManager?.unSubscribeAllEvents();
	}
}

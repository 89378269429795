import image from "@/assets/Animitions/chess.json";
import logo from "@/assets/images/logo/chess-logo.png";
import GameWrapper from "@/components/GameWrapper";
import LoaderProgress from "@/components/LoaderProgress";
import Lottie from "react-lottie-player";
interface Props {
	percent: number;
}

function GameProgressBar({ percent }: Props) {
	return (
		<GameWrapper>
			<div className=" gap-2 absolute top-0 pt-10 flex items-center  flex-col text-white text-3xl size-full  bg-chess overflow-hidden">
				<img alt="bg-log" className="h-auto max-w-64 w-full" src={logo} />
				<div className="w-full  ">
					<LoaderProgress progress={percent} />
				</div>
				<Lottie
					play
					animationData={image}
					style={{
						width: "100%",
					}}
				/>
			</div>
		</GameWrapper>
	);
}

export default GameProgressBar;
